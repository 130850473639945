import React from 'react';
import { Route, useParams, Routes } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';

import AssessmentForm from '../assessments/AssessmentForm';
import NewCampaignInvitation from './NewCampaignInvitation';
import { GetCampaign } from '../../operations.graphql';
import Loading from '../Loading';

function CampaignInvitationsRouter() {
  const { campaignId } = useParams();
  const { loading, error, data } = useQuery(GetCampaign, {
    variables: { campaignId },
  });

  if (error) {
    return (
      <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
        <Row>
          <Col xs={12}>
            <p>{error}</p>
          </Col>
        </Row>
      </Container>
    );
  }

  if (loading) {
    return <Loading />;
  }

  const { campaign } = data;

  return (
    <Routes>
      <Route path="new" element={<NewCampaignInvitation campaign={campaign} />} />
      <Route path="current_user" element={<AssessmentForm campaign={campaign} intervieweeRole="employee" selfAssessment />} />
      <Route path="employee" element={<AssessmentForm campaign={campaign} intervieweeRole="employee" />} />
      <Route path="customer" element={<AssessmentForm campaign={campaign} intervieweeRole="customer" />} />
      <Route path="caregiver" element={<AssessmentForm campaign={campaign} intervieweeRole="caregiver" />} />
    </Routes>
  );
}

export default CampaignInvitationsRouter;
