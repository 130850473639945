import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Card, Container } from 'react-bootstrap';
import { Code } from 'react-content-loader';

import { FacilitiesQuery } from '../operations.graphql';
import UserContext from '../contexts/UserContext';
import FacilitySummary from './facilities/FacilitySummary';

function DashboardContent() {
  // eslint-disable-next-line no-unused-vars
  const currentUser = useContext(UserContext);
  const { loading, error, data } = useQuery(FacilitiesQuery, { fetchPolicy: 'network-only' });

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <Code />
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { facilities } = data;

  if (!Array.isArray(facilities) || facilities.length <= 0) {
    return <Navigate to="/onboarding" />;
  }

  return facilities.map((facility) => (
    <FacilitySummary key={facility.id} facility={facility} />
  ));
}

function Dashboard() {
  return <Container>
    <DashboardContent />
  </Container>
}

export default Dashboard;
