import axios from 'axios';
import React, { useContext } from 'react';
import { Dropdown, Image, Navbar, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import BlankProfilePicture from '../../images/blank-profile-picture.png';
import Logo from '../../images/logo-arbitryum-full.png';
import UserContext from '../contexts/UserContext';

function Header() {
  const { avatar_url: avatarUrl, name, admin, analyst } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <Navbar className="px-3" bg="primary" variant="dark">
      <Navbar.Brand href="/">
        <Image alt="" src={Logo} width="138" height="30" className="d-inline-block align-top" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Dropdown as={NavItem} align="end">
          <Dropdown.Toggle id="dropdown-basic">
            <Image src={avatarUrl || BlankProfilePicture} width="30" height="30" roundedCircle />
            <span className="ms-2">{name}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkContainer to="/">
              <Dropdown.Item>{t('header.dashboard')}</Dropdown.Item>
            </LinkContainer>
            <LinkContainer to="/my_account">
              <Dropdown.Item>{t('header.myAccount')}</Dropdown.Item>
            </LinkContainer>
            {(admin || analyst) && <Dropdown.Divider />}
            {admin && <Dropdown.Item href="/admin">{t('header.admin')}</Dropdown.Item>}
            {analyst && <Dropdown.Item href="/campaigns/analysis">{t('header.campaigns_analysis')}</Dropdown.Item>}
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={async () => {
                await axios.delete('/users/sign_out');
                window.location.href = '/';
              }}
            >
              {t('header.signOut')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
