import React from 'react';
import { ListGroup } from "react-bootstrap";
import { actionPlanType } from '../../types';

function ActionsList({ actionPlan }) {
  const { actions } = actionPlan;
  return (
    <ListGroup>
      {actions.map(action => (<ListGroup.Item key={action.id}>{action.goodPractice.title}</ListGroup.Item>))}
    </ListGroup>
  );
}


ActionsList.propTypes = {
  actionPlan: actionPlanType.isRequired,
};

export default ActionsList;
