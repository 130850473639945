import PropTypes from 'prop-types';
import questionType from './questionType';

const questionGroupType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  priority: PropTypes.number,
  questions: PropTypes.arrayOf(questionType).isRequired,
  updatedAt: PropTypes.string.isRequired,
});

export default questionGroupType;
