import { faArrowRight, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar, Col, Form, InputGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpeechRecognition } from 'react-speech-kit';
import { assessmentRolesType } from '../../types';
import QuestionTypes from './QuestionTypes';

function AssessmentQuestionInput({ type, value, name, options, interviewee, handleCheckboxSelection, handleRadioSelection, onTextInputSubmitted, gender }) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues: {
      [name]: value,
    },
  });
  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      setValue(name, result);
    },
  });

  switch (type) {
    case QuestionTypes.CHECKBOX:
      return options.map(({ option, displayName }) => (
        <Col key={option} sm={6} className="mb-4">
          <div className="d-grid gap-1">
            <Button size="lg" className="text-dark fw-bold custom-control custom-checkbox" value={option} onClick={() => handleCheckboxSelection(name, option)}>
              <input
                type="checkbox"
                id="custom-checkbox"
                className="custom-control-input"
                checked={(value || []).includes(option)}
                onChange={() => handleCheckboxSelection(name, option)}
                style={{ display: 'none' }}
              />
              <div className="custom-control-label">{displayName}</div>
            </Button>
          </div>
        </Col>
      ));
    case QuestionTypes.SCALE:
      return (
        <Col sm={12} className="mb-4 d-flex justify-content-center">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name={name} defaultValue={value}>
              {options.map(({ option, displayName }) => (
                <ToggleButton
                  size="lg"
                  key={option}
                  className="text-dark fw-bold"
                  value={option}
                  active={value === option}
                  onClick={() => handleRadioSelection(name, option)}
                >
                  {displayName}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </ButtonToolbar>
        </Col>
      );
    case QuestionTypes.RADIO:
      return options.map(({ option, displayName }) => (
        <Col className="mb-4" key={option}>
          <div className="d-grid">
            <Button size="lg" className="text-dark fw-bold" value={option} active={value === option} onClick={() => handleRadioSelection(name, option)}>
              {displayName}
            </Button>
          </div>
        </Col>
      ));
    case QuestionTypes.TEXTAREA:
    case QuestionTypes.NUMBER:
    case QuestionTypes.TEXT:
    default: {
      const inputType = () => {
        switch (type) {
          case QuestionTypes.TEXT:
            return 'text';
          case QuestionTypes.TEXTAREA:
            return 'textarea';
          case QuestionTypes.NUMBER:
          default:
            return 'number';
        }
      };

      const val = watch(name);

      const onSubmit = (formData) => {
        let data = formData[name];
        if (type === QuestionTypes.NUMBER) {
          data = parseInt(data, 10);
        }
        if (listening) {
          stop();
        }
        onTextInputSubmitted(name, data);
      };

      return (
        <Col xs={12} className="mb-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                {supported && (
                  <Button variant="info" size="lg" onClick={() => (listening ? stop() : listen({ lang: 'fr-FR' }))}>
                    <FontAwesomeIcon icon={faMicrophone} />
                  </Button>
                )}
                <Form.Control
                  {...register(`${name}`, { required: true })}
                  size="lg"
                  placeholder={t(`campaigns.assessments.${interviewee}.${name}`, { context: gender })}
                  type={inputType()}
                  lang="fr"
                  isInvalid={!!errors[name]}
                />
                <Form.Control.Feedback type="invalid" className="order-last">
                  {errors[name] && t(`errors.models.assessments.attributes.${name}.required`)}
                </Form.Control.Feedback>
                <Button variant="primary" type="submit" size="lg" disabled={!val}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </InputGroup>
              <Form.Text>{listening && <div>{t('campaigns.assessments.listening')}</div>}</Form.Text>
            </Form.Group>
          </Form>
        </Col>
      );
    }
  }
}

AssessmentQuestionInput.propTypes = {
  type: PropTypes.oneOf([QuestionTypes.CHECKBOX, QuestionTypes.RADIO, QuestionTypes.TEXT, QuestionTypes.TEXTAREA, QuestionTypes.SCALE, QuestionTypes.NUMBER])
    .isRequired,
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      displayName: PropTypes.string,
    }),
  ),
  interviewee: assessmentRolesType.isRequired,
  handleCheckboxSelection: PropTypes.func.isRequired,
  handleRadioSelection: PropTypes.func.isRequired,
  onTextInputSubmitted: PropTypes.func.isRequired,
  gender: PropTypes.oneOf(['male', 'female']),
};

AssessmentQuestionInput.defaultProps = {
  options: undefined,
  value: undefined,
  gender: 'male',
};

export default AssessmentQuestionInput;
