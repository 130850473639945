import { ApolloProvider } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AssessmentForm from '../src/components/assessments/AssessmentForm';
import NewCampaignAssessment from '../src/components/assessments/NewCampaignAssessment';
import Loading from '../src/components/Loading';
import FullPageLayout from '../src/layouts/FullPageLayout';
import { createCache, createClient } from '../src/utils/apollo';
import '../src/utils/i18n';

// eslint-disable-next-line no-unused-vars
function CampaignAssessmentApp({ campaign, token }) {
  const campaignObj = JSON.parse(campaign);
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <ApolloProvider client={createClient(createCache())}>
          <FullPageLayout>
            <Routes>
              <Route path="/campaigns/:campaignId/assessments/new" element={<NewCampaignAssessment campaign={campaignObj} />} />
              <Route
                path="/campaigns/:campaignId/assessments/current_user"
                element={<AssessmentForm intervieweeRole="employee" selfAssessment />}
              />
              <Route path="/campaigns/:campaignId/assessments/employee" element={<AssessmentForm campaign={campaignObj} intervieweeRole="employee" />} />
              <Route path="/campaigns/:campaignId/assessments/customer" element={<AssessmentForm campaign={campaignObj} intervieweeRole="customer" />} />
              <Route
                path="/campaigns/:campaignId/assessments/caregiver"
                element={<AssessmentForm campaign={campaignObj} intervieweeRole="caregiver" />}
              />
            </Routes>
          </FullPageLayout>
        </ApolloProvider>
      </Router>
    </Suspense>
  );
}

CampaignAssessmentApp.propTypes = {
  campaign: PropTypes.string.isRequired,
  token: PropTypes.string,
};

CampaignAssessmentApp.defaultProps = {
  token: undefined,
};

export default CampaignAssessmentApp;
