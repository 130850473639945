import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import QRCode from "react-qr-code";
import { SendCampaignInvitations } from '../../operations.graphql';
import { campaignType } from '../../types';
import EmailsInput from '../inputs/EmailsInput';
import ClipboardCopy from '../ClipboardCopy';

function NewCampaignInvitation({ campaign }) {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const [error, setError] = useState(null);
  const [emailList, setEmailList] = useState([]);
  const { register, handleSubmit } = useForm();
  const [sendCampaignInvitations, { loading, data }] = useMutation(SendCampaignInvitations);
  const { ref } = register('EmailsInput');
  const assessmentUrl = `https://${window.location.hostname}${campaign.assessmentUrl}`

  if (data) {
    const {
      sendCampaignInvitations: { emails, errors },
    } = data;

    if (errors && errors.length > 0) {
      console.error(errors);
    } else if (emails) {
      return <Navigate to="/" />;
    }
  }

  const onSubmit = () => {
    if (emailList.length === 0) {
      setError(t('errors.atLeastOneEmail'));
      return;
    }
    sendCampaignInvitations({
      variables: {
        campaignId,
        emails: emailList,
      },
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.invitations.title')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('onboarding.invitations.description')}</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-grid gap-2">
                <EmailsInput inputRef={ref} emailList={emailList} setEmailList={setEmailList} />
                <Button type="submit" disabled={loading}>
                  {t('onboarding.invitations.submit')}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} className="mt-3">
        <Card>
          <Card.Body>
            <p>{t('onboarding.invitations.shareLink')}</p>
            <ClipboardCopy copyText={assessmentUrl}/>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} className="mt-3">
        <Card>
          <Card.Body>
            <p>{t('onboarding.invitations.qrCode')}</p>
            <QRCode value={assessmentUrl} />
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} className="mt-3">
        <LinkContainer to="/">
          <Button variant="link">{t('campaigns.assessments.new.back')}</Button>
        </LinkContainer>
      </Col>
    </Row>
  );
}

NewCampaignInvitation.propTypes = {
  campaign: campaignType.isRequired,
};

export default NewCampaignInvitation;
