import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Code } from 'react-content-loader';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CreateGroup, FacilityTypesQuery } from '../../operations.graphql';

function StepCreateGroupForm() {
  const uniqId = () => {
    const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
    return randLetter + Date.now();
  };
  const { t } = useTranslation();
  const [facilityKeys, setFacilityKeys] = useState([uniqId()]);
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const { loading: queryLoading, error, data } = useQuery(FacilityTypesQuery);
  const [createGroup, { loading, data: createdGroup }] = useMutation(CreateGroup);

  const onSubmit = ({ group }) => {
    createGroup({
      variables: {
        group,
      },
    });
  };

  if (createdGroup) {
    return <Navigate to="/" />;
  }

  if (error) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('onboarding.createGroup.title')}</h1>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Body>{error.message}</Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  if (queryLoading) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('onboarding.createGroup.title')}</h1>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Code />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  const { facilityTypes } = data;

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.createGroup.title')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('onboarding.createGroup.description')}</p>
            <Form className="d-grid gap-1" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="groupName">
                <Form.Label>{t('attributes.group.name')}</Form.Label>
                <Form.Control
                  {...register('group[name]', { required: true })}
                  type="text"
                  placeholder={t('onboarding.placeholders.groupName')}
                  isInvalid={!!errors['group[name]']}
                />
                <Form.Control.Feedback type="invalid">{errors['group[name]'] && t('errors.models.group.attributes.name.required')}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="groupLegalStatus">
                <Form.Label>{t('attributes.group.legalStatus')}</Form.Label>
                <Form.Control {...register('group[legalStatus]', { required: true })} as="select" isInvalid={!!errors['group[legalStatus]']}>
                  {['private_corporation', 'nonprofit_corporation', 'public_corporation'].map((status) => (
                    <option key={status} value={status}>
                      {t(`attributes.group.legalStatuses.${status}`)}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors['group[legalStatus]'] && t('errors.models.group.attributes.legalStatus.required')}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="my-3">
                <h3>{t('onboarding.createGroup.facilities')}</h3>
                {facilityKeys.map((key, index) => (
                  <div className="d-grid gap-2" key={key}>
                    <Form.Group controlId={`groupFacilityName${index}`}>
                      <Form.Control
                        {...register(`group[facilitiesAttributes[${index}][name]]`, { required: true })}
                        type="text"
                        placeholder={t('onboarding.placeholders.facilityName')}
                        isInvalid={!!errors[`group[facilitiesAttributes[${index}][name]]`]}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors[`group[facilitiesAttributes[${index}][name]]`] && t('errors.models.facility.attributes.name.required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={`groupFacilityType${index}`}>
                      <Form.Control
                        {...register(`group[facilitiesAttributes[${index}][facilityTypeId]]`, { required: true })}
                        as="select"
                        isInvalid={!!errors[`group[facilitiesAttributes[${index}][facilityTypeId]]`]}
                      >
                        {facilityTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors[`group[facilitiesAttributes[${index}][facilityTypeId]]`] && t('errors.models.facility.attributes.facilityType.required')}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId={`groupFacilityType${index}`}>
                      <div className="d-grid gap-1">
                        {facilityKeys.length > 1 && (
                          <Button
                            variant="danger"
                            onClick={() => {
                              const keys = [...facilityKeys];
                              keys.splice(index, 1);
                              setFacilityKeys(keys);
                            }}
                          >
                            {t('onboarding.createGroup.removeFacility')}
                          </Button>
                        )}
                      </div>
                    </Form.Group>
                  </div>
                ))}
                <div className="d-grid gap-1">
                  <Button
                    onClick={() => {
                      setFacilityKeys([...facilityKeys, uniqId()]);
                    }}
                    variant="secondary"
                  >
                    {t('onboarding.createGroup.addFacility')}
                  </Button>
                </div>
              </div>
              <div className="d-grid gap-2">
                <Button type="submit" disabled={loading}>
                  {t('onboarding.createGroup.submit')}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default StepCreateGroupForm;
