import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { campaignType } from '../../types';
import CampaignActive from './CampaignActive';
import CampaignFinished from './CampaignFinished';
import CampaignReportSummary from './CampaignReportSummary';
import CampaignStatus from './CampaignStatus';

function CampaignCard({ campaign, index }) {
  const { t } = useTranslation();
  const { campaignState } = campaign;
  const title = t(`campaigns.${campaign.campaignState === CampaignStatus.FINISHED ? 'finished' : 'active'}.title`, {
    index,
    startDate: moment(campaign.createdAt).format('LL'),
    endDate: moment(campaign.finishedAt).format('LL'),
  })

  if (campaignState === CampaignStatus.FINISHED) {
    return <CampaignFinished campaign={campaign} title={title} />;
  }
  if (campaignState === CampaignStatus.REPORT_PUBLISHED) {
    return <CampaignReportSummary campaign={campaign} title={title} />;
  }
  return <CampaignActive campaign={campaign} title={title} />;
}

CampaignCard.propTypes = {
  campaign: campaignType.isRequired,
  index: PropTypes.number
};

CampaignCard.defaultProps = {
  index: 0
};

export default CampaignCard;
