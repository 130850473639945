import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Code } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import { CampaignActionsSuggestions } from '../../operations.graphql';
import ActionDetails from './ActionDetails';
import ActionListLayout from './ActionListLayout';
import { campaignType } from '../../types';

function ActionsSelectionList({ campaign }) {
  const { t } = useTranslation();
  const { facility } = campaign;
  const [selectedActions, setSelectedActions] = useState([]);
  const [expandedActionInfo, setExpandedActionInfo] = useState([]);
  const { loading, error, data } = useQuery(CampaignActionsSuggestions, {
    variables: { campaignId: campaign.id },
  });

  const updateActionStartDate = (action, date) => {
    const updatedAction = action;
    updatedAction.startDate = date;
    setSelectedActions([...selectedActions.filter(e => e.id !== action.id), updatedAction]);
  };

  const updateActionEndDate = (action, date) => {
    const updatedAction = action;
    updatedAction.endDate = date;
    setSelectedActions([...selectedActions.filter(e => e.id !== action.id), updatedAction]);
  };

  if (loading) {
    return (
      <ActionListLayout
        selectedActions={selectedActions}
        facility={facility}
        updateActionStartDate={updateActionStartDate}
        updateActionEndDate={updateActionEndDate}
      >
        <Card>
          <Card.Body>
            <Code />
          </Card.Body>
        </Card>
      </ActionListLayout>
    );
  }

  if (error) {
    return (
      <ActionListLayout
        selectedActions={selectedActions}
        facility={facility}
        updateActionStartDate={updateActionStartDate}
        updateActionEndDate={updateActionEndDate}
      >
        <Card>
          <Card.Body>
            <div>{t('campaigns.actionPlan.new.error')}</div>
          </Card.Body>
        </Card>
      </ActionListLayout>
    );
  }

  const { goodPractices } = data;

  return (
    <ActionListLayout
      selectedActions={selectedActions}
      facility={facility}
      updateActionStartDate={updateActionStartDate}
      updateActionEndDate={updateActionEndDate}
    >
      {goodPractices.map(action => (
        <ActionDetails
          key={action.id}
          action={action}
          selectedAction={selectedActions.includes(action)}
          expandedActionInfo={expandedActionInfo === action.id}
          deselectAction={() => setSelectedActions(selectedActions.filter(e => e.id !== action.id))}
          selectAction={() => setSelectedActions([...selectedActions, { startDate: new Date(), endDate: new Date(), ...action }])}
          setExpandedActionInfo={() => expandedActionInfo === action.id ? setExpandedActionInfo(null) : setExpandedActionInfo(action.id)}
        />
      ))}
    </ActionListLayout>
  );
}

ActionsSelectionList.propTypes = {
  campaign: campaignType.isRequired,
}

export default ActionsSelectionList;
