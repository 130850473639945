import React from 'react';
import { useMutation } from '@apollo/client';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';

import { CreateCampaign } from '../../operations.graphql';

function NewCampaign() {
  const { t } = useTranslation();
  const { facilityId } = useParams();
  const {
    register,
    handleSubmit,

    formState: {
      /* watch, */ errors,
    },
  } = useForm();
  const [createCampaign, { loading, data }] = useMutation(CreateCampaign);

  const onSubmit = formData => {
    const { campaign: {
      averageCustomerAge,
      averageCustomerGir,
      averageProfessionalCountPerCustomer,
      cognitiveDisorderCustomersTotalCount,
      femaleCustomersTotalCount,
      maleCustomersTotalCount,
      customerTotalCount,
      averageCustomerPathos,
      professionalsTotalCount,
      hasSecureFacility,
      hasDayCare,
    } } = formData;

    createCampaign({
      variables: {
        facilityId,
        campaignAttributes: {
          averageCustomerAge: parseInt(averageCustomerAge, 10),
          averageCustomerGir: parseInt(averageCustomerGir, 10),
          averageProfessionalCountPerCustomer: parseInt(averageProfessionalCountPerCustomer, 10),
          cognitiveDisorderCustomersTotalCount: parseInt(cognitiveDisorderCustomersTotalCount, 10),
          femaleCustomersTotalCount: parseInt(femaleCustomersTotalCount, 10),
          maleCustomersTotalCount: parseInt(maleCustomersTotalCount, 10),
          customerTotalCount: parseInt(customerTotalCount, 10),
          averageCustomerPathos: parseInt(averageCustomerPathos, 10),
          professionalsTotalCount: parseInt(professionalsTotalCount, 10),
          hasSecureFacility,
          hasDayCare,
        },
      },
    });
  };

  if (data) {
    const { createCampaign: { id } } = data;
    return <Navigate to={`/campaigns/${id}`} />;
  }

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('campaigns.new.title')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('campaigns.new.description')}</p>

            <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group controlId="campaign[customerTotalCount]">
                <Form.Label>{t('attributes.campaign.customerTotalCount')}</Form.Label>
                <Form.Control
                  {...register('campaign[customerTotalCount]', { required: true, min: 1 })}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[customerTotalCount]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[customerTotalCount]"] && (
                    t('errors.models.campaign.attributes.customerTotalCount.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[femaleCustomersTotalCount]">
                <Form.Label>{t('attributes.campaign.femaleCustomersTotalCount')}</Form.Label>
                <Form.Control
                  {...register('campaign[femaleCustomersTotalCount]', { required: true, min: 0 })}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[femaleCustomersTotalCount]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[femaleCustomersTotalCount]"] && (
                    t('errors.models.campaign.attributes.femaleCustomersTotalCount.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[maleCustomersTotalCount]">
                <Form.Label>{t('attributes.campaign.maleCustomersTotalCount')}</Form.Label>
                <Form.Control
                  {...register('campaign[maleCustomersTotalCount]', { required: true, min: 0 })}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[maleCustomersTotalCount]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[maleCustomersTotalCount]"] && (
                    t('errors.models.campaign.attributes.maleCustomersTotalCount.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[cognitiveDisorderCustomersTotalCount]">
                <Form.Label>{t('attributes.campaign.cognitiveDisorderCustomersTotalCount')}</Form.Label>
                <Form.Control
                  {...register(
                    'campaign[cognitiveDisorderCustomersTotalCount]',
                    { required: true, min: 0 },
                  )}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[cognitiveDisorderCustomersTotalCount]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[cognitiveDisorderCustomersTotalCount]"] && (
                    t('errors.models.campaign.attributes.cognitiveDisorderCustomersTotalCount.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[averageCustomerAge]">
                <Form.Label>{t('attributes.campaign.averageCustomerAge')}</Form.Label>
                <Form.Control
                  {...register('campaign[averageCustomerAge]', { required: true, min: 0 })}
                  type="number"
                  step={0.1}
                  min={0}
                  isInvalid={!!errors["campaign[averageCustomerAge]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[averageCustomerAge]"] && (
                    t('errors.models.campaign.attributes.averageCustomerAge.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[averageCustomerGir]">
                <Form.Label>{t('attributes.campaign.averageCustomerGir')}</Form.Label>
                <Form.Control
                  {...register('campaign[averageCustomerGir]', { required: true, min: 0 })}
                  type="number"
                  step={0.01}
                  min={0}
                  isInvalid={!!errors["campaign[averageCustomerGir]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[averageCustomerGir]"] && (
                    t('errors.models.campaign.attributes.averageCustomerGir.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[averageCustomerPathos]">
                <Form.Label>{t('attributes.campaign.averageCustomerPathos')}</Form.Label>
                <Form.Control
                  {...register('campaign[averageCustomerPathos]', { required: true, min: 0 })}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[averageCustomerPathos]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[averageCustomerPathos]"] && (
                    t('errors.models.campaign.attributes.averageCustomerPathos.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[averageProfessionalCountPerCustomer]">
                <Form.Label>{t('attributes.campaign.averageProfessionalCountPerCustomer')}</Form.Label>
                <Form.Control
                  {...register(
                    'campaign[averageProfessionalCountPerCustomer]',
                    { required: true, min: 0 },
                  )}
                  type="number"
                  step={0.01}
                  min={0}
                  isInvalid={!!errors["campaign[averageProfessionalCountPerCustomer]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[averageProfessionalCountPerCustomer]"] && (
                    t('errors.models.campaign.attributes.averageProfessionalCountPerCustomer.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[professionalsTotalCount]">
                <Form.Label>{t('attributes.campaign.professionalsTotalCount')}</Form.Label>
                <Form.Control
                  {...register('campaign[professionalsTotalCount]', { required: true, min: 1 })}
                  type="number"
                  step={1}
                  min={0}
                  isInvalid={!!errors["campaign[professionalsTotalCount]"]} />
                <Form.Control.Feedback type="invalid">
                  {errors["campaign[professionalsTotalCount]"] && (
                    t('errors.models.campaign.attributes.professionalsTotalCount.required')
                  )}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="campaign[hasSecureFacility]">
                <Form.Check
                  type='checkbox'
                  {...register('campaign[hasSecureFacility]')}
                  id="hasSecureFacilityCheckbox"
                  label={t('attributes.campaign.hasSecureFacility')} />
              </Form.Group>

              <Form.Group controlId="campaign[hasDayCare]">
                <Form.Check
                  type='checkbox'
                  {...register('campaign[hasDayCare]')}
                  id="hasDayCareCheckbox"
                  label={t('attributes.campaign.hasDayCare')} />
              </Form.Group>

              <Button disabled={loading} type="submit">{t('campaigns.new.submit')}</Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default NewCampaign;
