import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { actionPlanType } from '../../types';

function ActionPlanGantt({ actionPlan }) {
  const { actions } = actionPlan;
  const data = actions.map(({ startDate, endDate, goodPractice: { title } }) => ({
      x: title,
      y: [
        new Date(startDate).getTime(),
        new Date(endDate).getTime()
      ]
    }));
  const series = [
    {
      data
    }
  ];
  const options = {
    chart: {
      // height: 350,
      type: 'rangeBar'
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    xaxis: {
      type: 'datetime'
    }
  };
  return (<ReactApexChart options={options} series={series} type="rangeBar" height={350} />);
}

ActionPlanGantt.propTypes = {
  actionPlan: actionPlanType.isRequired,
};

export default ActionPlanGantt;
