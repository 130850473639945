import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SwitchTransition } from 'react-transition-group';
import { campaignType, surveyRolesType } from '../../types';
import usePersistedState from '../../utils/usePersistedState';
import SlideTransition from '../SlideTransition';
import SurveyQuestion from './SurveyQuestion';
import SurveyRoles from './SurveyRoles';
import SurveySubmit from './SurveySubmit';
import surveyTemplateType from '../../types/surveyTemplateType';

const TransitionDirections = {
  LEFT: 'left',
  RIGHT: 'right',
};

function ChooseGender({ onChoice }) {
  const { t } = useTranslation();
  return (
    <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
      <Row>
        <Col xs={12} className="d-grid gap-1">
          <Card.Text className="text-center mb-5 lead">{t('survey.chooseGender')}</Card.Text>
          <Button className="mb-3" variant="outline-primary" size="lg" onClick={() => onChoice('homme')}>
            Homme
          </Button>
          <Button className="mb-3" variant="outline-primary" size="lg" onClick={() => onChoice('femme')}>
            Femme
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
function Survey({ campaign, surveyTemplate, intervieweeRole, selfAssessment, timeout, wrongRoleButton, token }) {
  const [gender, setGender] = usePersistedState(`assessmentFormGender-${token || campaign.id}-${intervieweeRole}`, undefined);

  const { t } = useTranslation();
  const [seconds, setSeconds] = usePersistedState(`assessmentFormSeconds-${token || campaign.id}-${intervieweeRole}`, 0);
  const [questionSeconds, setQuestionSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(() => seconds + 1);
      setQuestionSeconds(() => questionSeconds + 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, questionSeconds]);

  const [state, setState] = usePersistedState(`assessmentFormState-${token || campaign.id}-${intervieweeRole}`, {
    step: 0,
    transitionDirection: TransitionDirections.RIGHT,
  });

  const [answers, setAnswers] = usePersistedState(`assessmentFormAnswers-${token || campaign.id}-${intervieweeRole}`, { ...[] });
  const [comments, setComments] = usePersistedState(`commentsForAssessmentFormAnswers-${token || campaign.id}-${intervieweeRole}`, { ...[] });
  const [questionsTimings, setQuestionsTimings] = usePersistedState(`assessmentFormTimings-${token || campaign.id}-${intervieweeRole}`, {});

  if (gender === undefined) {
    return <ChooseGender onChoice={setGender} />;
  }

  const arrayForSort = [...surveyTemplate.questionGroups];
  const questions = arrayForSort
    .sort((a, b) => a.priority - b.priority)
    .flatMap((qg) => qg.questions)
    .flatMap((q) => {
      if (q.dependantQuestions.length > 0) {
        return [q, ...q.dependantQuestions];
      }
      return q;
    })
    .filter((q) => {
      switch (intervieweeRole) {
        case SurveyRoles.FAMILY:
          return q.familyEnabled;
        case SurveyRoles.RESIDENT:
          return q.residentEnabled;
        case SurveyRoles.PROFESSIONAL:
          return q.professionalEnabled;
        default:
          return false;
      }
    });

  const { step, transitionDirection } = state;
  const questionsCount = questions.length;

  if (step >= questionsCount) {
    ReactGA.timing({
      category: 'Assessments',
      variable: intervieweeRole,
      value: seconds * 1000, // in milliseconds
      label: 'full assessment',
    });
    Object.keys(questionsTimings).forEach((key) => {
      ReactGA.timing({
        category: 'Assessments',
        variable: intervieweeRole,
        value: questionsTimings[key] * 1000, // in milliseconds
        label: key,
      });
    });
    return (
      <SurveySubmit
        campaignId={campaign.id}
        answers={answers}
        comments={comments}
        intervieweeRole={intervieweeRole}
        gender={gender}
        selfAssessment={selfAssessment}
        token={token}
      />
    );
  }

  const question = questions[step];
  const { id } = question;
  // const { gender } = answers;

  const nextStep = () => {
    // eslint-disable-next-line no-shadow
    const { step } = state;
    setState({
      step: step + 1,
      transitionDirection: TransitionDirections.RIGHT,
    });
    setQuestionsTimings({
      ...questionsTimings,
      [id]: questionSeconds,
    });
    setQuestionSeconds(0);
  };

  const prevStep = (prevQuestionId) => {
    // eslint-disable-next-line no-shadow
    const { step } = state;
    if (prevQuestionId) delete answers[prevQuestionId];
    setState({
      step: step - 1,
      transitionDirection: TransitionDirections.LEFT,
    });
    setQuestionSeconds(questionsTimings[questions[step - 1].id]);
  };

  // eslint-disable-next-line no-shadow
  const onCommentSubmitted = (questionId, comment) => {
    if (comment.trim()) {
      setComments({
        ...comments,
        [questionId]: { questionId, comment },
      });
      nextStep();
    }
  };

  // eslint-disable-next-line no-shadow
  const handleRadioSelection = (key, value, comment) => {
    setAnswers({
      ...answers,
      [key]: { questionId: key, answerOptionIds: [value] },
    });
    onCommentSubmitted(key, comment);
    setTimeout(() => nextStep(), timeout);
  };

  // eslint-disable-next-line no-shadow
  const handleCheckboxSelection = (key, value, comment) => {
    let val = (answers[key] && answers[key].answerOptionIds) || [];
    if (val.includes(value)) {
      val = val.filter((v) => v !== value);
    } else {
      val.push(value);
    }
    setAnswers({
      ...answers,
      [key]: { questionId: key, answerOptionIds: val },
    });
    onCommentSubmitted(key, comment);
  };

  // eslint-disable-next-line no-shadow
  const onRankingSelectionSubmitted = (key, value, comment) => {
    setAnswers({
      ...answers,
      [key]: { questionId: key, answerSelectedOptions: value },
    });
    onCommentSubmitted(key, comment);
    nextStep();
  };
  // eslint-disable-next-line no-shadow
  const onTextInputSubmitted = (key, value, comment) => {
    setAnswers({
      ...answers,
      [key]: { questionId: key, value: value.toString() },
    });
    onCommentSubmitted(key, comment);
    nextStep();
  };

  const val = answers[id];
  const progress = (step / questionsCount) * 100;
  const showNextButton = val !== undefined && (question.kind === 'checkbox' || transitionDirection === TransitionDirections.LEFT);

  if (question.answerOptions && !question.answerOptions.some((ao) => answers[question.question.id].answerOptionIds.includes(ao.id))) {
    if (transitionDirection === TransitionDirections.RIGHT) {
      nextStep();
    } else {
      prevStep();
    }
  }
  return (
    <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
      <Row>
        <Col>
          <Row>
            <Col xs={12}>
              <h1>{(selfAssessment && t(`campaigns.assessments.self.title`)) || t(`campaigns.assessments.${intervieweeRole}.title`)}</h1>
            </Col>
            <Col xs={12} className="mt-3">
              {t('campaigns.assessments.progress')}
              <ProgressBar now={progress} label={`${Math.floor(progress)}%`} visuallyHidden />
            </Col>
          </Row>

          <div className={transitionDirection}>
            <SwitchTransition>
              <SlideTransition key={id}>
                <SurveyQuestion
                  question={question}
                  choice={val}
                  interviewee={intervieweeRole}
                  handleCheckboxSelection={handleCheckboxSelection}
                  handleRadioSelection={handleRadioSelection}
                  onTextInputSubmitted={onTextInputSubmitted}
                  onRankingSelectionSubmitted={onRankingSelectionSubmitted}
                  onCommentSubmitted={onCommentSubmitted}
                  // gender={gender}
                />
              </SlideTransition>
            </SwitchTransition>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="float-start">
            {step > 0 && (
              <Button variant="link" onClick={() => prevStep(question.id)}>
                {t(`campaigns.assessments.${intervieweeRole}.back`)}
              </Button>
            )}
            {step === 0 &&
              transitionDirection === 'right' &&
              (wrongRoleButton || (
                <small>
                  <Link to={`/campaigns/${campaign.id}/assessments/new`}>{t('campaigns.assessments.wrongRole')}</Link>
                </small>
              ))}
          </div>
          <div className="float-end">{showNextButton && <Button onClick={() => nextStep()}>{t(`campaigns.assessments.${intervieweeRole}.next`)}</Button>}</div>
        </Col>
      </Row>
    </Container>
  );
}

Survey.propTypes = {
  surveyTemplate: surveyTemplateType.isRequired,
  intervieweeRole: surveyRolesType.isRequired,
  selfAssessment: PropTypes.bool,
  timeout: PropTypes.number,
  campaign: campaignType.isRequired,
  wrongRoleButton: PropTypes.node,
  token: PropTypes.string,
};
ChooseGender.propTypes = {
  onChoice: PropTypes.func.isRequired,
};

Survey.defaultProps = {
  selfAssessment: false,
  timeout: 200,
  wrongRoleButton: undefined,
  token: undefined,
};

export default Survey;
