import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { campaignType } from '../../types';

// eslint-disable-next-line no-unused-vars
function CampaignFinished({ campaign, title }) {
  return (
    <Card className={campaign.campaignState}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Row>
          <Col xs={4} />
          <Col xs={8} />
        </Row>
      </Card.Body>
    </Card>
  );
}

CampaignFinished.propTypes = {
  campaign: campaignType.isRequired,
  title: PropTypes.string.isRequired,
};

export default CampaignFinished;
