import PropTypes from 'prop-types';
import facilityType from './facilityType';
import questionType from './questionType';
import questionGroupType from './questionGroupType';

const surveyTemplateType = PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  facilityType: facilityType.isRequired,
  id: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(questionType).isRequired,
  questionGroups: PropTypes.arrayOf(questionGroupType).isRequired,
});

export default surveyTemplateType;
