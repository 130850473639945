import { useEffect, useState } from "react";

function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    () => (localStorage.getItem(key) !== 'undefined' && JSON.parse(localStorage.getItem(key))) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export default usePersistedState;
