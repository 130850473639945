import PropTypes from 'prop-types';
import AssessmentRoles from '../components/assessments/AssessmentRoles';
import SurveyRoles from '../components/assessments/SurveyRoles';
import TimelineStatus from '../components/campaigns/TimelineStatus';

export { default as actionPlanType } from './actionPlanType';
export { default as campaignType } from './campaignType';
export { default as facilityType } from './facilityType';
export { default as goodPracticeType } from './goodPracticeType';
export { default as userType } from './userType';

export const timelineStatusType = PropTypes.oneOf(Object.values(TimelineStatus));
export const assessmentRolesType = PropTypes.oneOf(Object.values(AssessmentRoles));
export const surveyRolesType = PropTypes.oneOf(Object.values(SurveyRoles));
