import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Row, Col, Button, Card,
} from 'react-bootstrap';

function NoActiveCampaignCard({ facilityId }) {
  const { t } = useTranslation();
  return (
    <Row>
      <Col className="pb-3">
        <Card>
          <Card.Body className="m-5 text-center">
            <p>
              {t('dashboard.noActiveCampaign.title')}
            </p>
            <p>
              {t('dashboard.noActiveCampaign.body')}
            </p>
            <LinkContainer to={`/facilities/${facilityId}/campaigns/new`}>
              <Button variant="outline-primary">{t('dashboard.noActiveCampaign.newCampaign')}</Button>
            </LinkContainer>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

NoActiveCampaignCard.propTypes = {
  facilityId: PropTypes.string.isRequired,
};

export default NoActiveCampaignCard;
