import React from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import { Code } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import { Card, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { GetActionPlan } from '../../operations.graphql';
import ShowActionPlan from './ShowActionPlan';

function ActionPlansRouter() {
  const { t } = useTranslation();
  const { actionPlanId } = useParams();
  const { loading, error, data } = useQuery(GetActionPlan, {
    variables: { actionPlanId },
  });

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <Code />
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return <div>{`${error}`}</div>;
  }

  const { actionPlan } = data;

  if (actionPlan == null) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('campaigns.actionPlan.show.title')}</h1>
          <p>{t('campaigns.actionPlan.show.notFound')}</p>
        </Col>
      </Row>
    );
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Row>
            <Col xs={12}>
              <h1>{t('campaigns.actionPlan.show.title')}</h1>
            </Col>
            <Col xs={12}>
              <ShowActionPlan actionPlan={actionPlan} />
            </Col>
          </Row>
        }
      />
    </Routes>
  );
}

export default ActionPlansRouter;
