import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateSurveyRecord } from '../../operations.graphql';
import { surveyRolesType } from '../../types';

export function SubmitError({ errors }) {
  return <Container>
    <Row>
      <Col xs={12}>
        <p className="text-center text-danger">{errors}</p>
      </Col>
    </Row>
  </Container>
}

SubmitError.propTypes = {
  errors: PropTypes.string.isRequired
}

export function SubmitSuccess({ children }) {
  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-5">
          <h1>{t(`campaigns.assessments.submitConfirmation.title`)}</h1>
          <p className="lead">
            <Trans i18nKey="multiline">{t(`campaigns.assessments.submitConfirmation.body`)}</Trans>
          </p>
          {children}
        </Col>
      </Row>
    </Container>
  )
}

SubmitSuccess.propTypes = {
  children: PropTypes.node
}

SubmitSuccess.defaultProps = {
  children: null
}

export function SubmitPage({ onClick, loading }) {
  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-5">
          <div className="d-grid gap-1">
            <h1>{t(`campaigns.assessments.submitForm.title`)}</h1>
            <p className="lead">
              <Trans i18nKey="multiline">{t(`campaigns.assessments.submitForm.privacy`)}</Trans>
            </p>
            <Button
              size="lg"
              className="mt-3"
              onClick={onClick}
              disabled={loading}
            >
              {t(`campaigns.assessments.submitForm.submit`)}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

SubmitPage.propTypes = {
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

SubmitPage.defaultProps = {
  loading: false,
  onClick: () => {}
};

export function BackToCampaignButton({ campaignId }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="d-grid mt-3">
      <Button
        size="lg"
        className="mt-3"
        onClick={() => navigate(`/campaigns/${campaignId}/assessments/new`)}
      >
        {t(`campaigns.assessments.submitForm.back`)}
      </Button>
    </div>
  )
}

BackToCampaignButton.propTypes = {
  campaignId: PropTypes.string.isRequired
}

function AssessmentFormSubmit({ campaignId, answers, comments, intervieweeRole, gender, selfAssessment, token }) {
  const [createSurveyRecord, { loading, data, errors }] = useMutation(CreateSurveyRecord);

  if (errors) {
    return (
      <SubmitError errors={errors} />
    );
  }

  if (data) {
    if (token) localStorage.removeItem(`CampaignInvitationRole-${token}`);
    localStorage.removeItem(`assessmentFormState-${token || campaignId}-${intervieweeRole}`);
    localStorage.removeItem(`assessmentFormAnswers-${token || campaignId}-${intervieweeRole}`);
    localStorage.removeItem(`assessmentFormGender-${token || campaignId}-${intervieweeRole}`);
    localStorage.removeItem(`commentsForAssessmentFormAnswers-${token || campaignId}-${intervieweeRole}`);
    return (
      <SubmitSuccess>
        {token && (
          <BackToCampaignButton campaignId={campaignId} />
        )}
      </SubmitSuccess>
    )
  }
  const answerRecords = Object.entries(answers).map(([, v]) => v);
  const commentsEntries = Object.entries(comments).map(([, v]) => v);
  return (
    <SubmitPage
      onClick={() => {
        if (loading) return;
        createSurveyRecord({
          variables: {
            campaignId,
            inviteToken: token,
            selfAssessment,
            gender,
            intervieweeRole,
            answers: answerRecords,
            comments: commentsEntries,
          },
        })
      }}
      disabled={loading}
    />
  );
}

AssessmentFormSubmit.propTypes = {
  campaignId: PropTypes.string.isRequired,
  answers: PropTypes.shape({}).isRequired,
  comments: PropTypes.shape({}).isRequired,
  intervieweeRole: surveyRolesType.isRequired,
  gender: PropTypes.string.isRequired,
  selfAssessment: PropTypes.bool,
  token: PropTypes.string,
};

AssessmentFormSubmit.defaultProps = {
  selfAssessment: false,
  token: undefined,
};

export default AssessmentFormSubmit;
