import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';

const defaultLocale = 'fr';

moment.locale(defaultLocale);

i18next
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: defaultLocale,
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18next;
