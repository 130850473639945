import { useQuery } from '@apollo/client';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Route, Routes, useParams } from 'react-router-dom';
import FullPageLayout from '../../layouts/FullPageLayout';
import { GetCampaign } from '../../operations.graphql';
import Loading from '../Loading';
import AssessmentForm from './AssessmentForm';
import NewCampaignAssessment from './NewCampaignAssessment';

function CampaignsAssessmentsRouter() {
  const { campaignId } = useParams();
  const { loading, error, data } = useQuery(GetCampaign, {
    variables: { campaignId },
  });

  if (error) {
    return (
      <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
        <Row>
          <Col xs={12}>
            <p>{error}</p>
          </Col>
        </Row>
      </Container>
    );
  }

  if (loading) {
    return <Loading />;
  }

  const { campaign } = data;

  return (
    <FullPageLayout>
      <Routes>
        <Route path="new" element={<NewCampaignAssessment campaign={campaign} />} />
        <Route path="current_user" element={<AssessmentForm campaign={campaign} intervieweeRole="employee" selfAssessment />} />
        <Route path="employee" element={<AssessmentForm campaign={campaign} intervieweeRole="employee" />} />
        <Route path="customer" element={<AssessmentForm campaign={campaign} intervieweeRole="customer" />} />
        <Route path="caregiver" element={<AssessmentForm campaign={campaign} intervieweeRole="caregiver" />} />
      </Routes>
    </FullPageLayout>
  );
}

export default CampaignsAssessmentsRouter;
