import React from 'react';
import ReactLoading from 'react-loading';

export default function Loading() {
  return (
    <div className="full-page bg-dark d-flex justify-content-center align-items-center">
      <ReactLoading type="bubbles" color="#06D6A0" />
    </div>
  );
}
