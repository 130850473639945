import CampaignStatus from "./CampaignStatus";
import TimelineStatus from "./TimelineStatus";

export default function getTimelineStatus(state, campaignState) {
  const stateIndex = Object.values(CampaignStatus).indexOf(state);
  const campaignStateIndex = Object.values(CampaignStatus).indexOf(campaignState);
  if (stateIndex === campaignStateIndex) {
    return TimelineStatus.ACTIVE;
  }
  if (stateIndex < campaignStateIndex) {
    return TimelineStatus.COMPLETE;
  }
  return TimelineStatus.DEFAULT;
}
