import { faMicrophone, faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpeechRecognition, useSpeechSynthesis } from 'react-speech-kit';
import { surveyRolesType } from '../../types';
import SurveyQuestionInput from './SurveyQuestionInput';
import SurveyRoles from './SurveyRoles';

function SurveyQuestion({
  question,
  choice,
  interviewee,
  handleCheckboxSelection,
  handleRadioSelection,
  onRankingSelectionSubmitted,
  onTextInputSubmitted,
  gender,
}) {
  const { t } = useTranslation();
  const {
    register,
    watch,
    setValue,
    // formState: { errors },
  } = useForm();
  const { speak, voices, speaking } = useSpeechSynthesis();
  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      setValue('comment', result);
    },
  });

  const langVoices = voices.filter((voice) => voice.lang === 'fr-FR');

  let title;
  switch (interviewee) {
    case SurveyRoles.FAMILY:
      title = question.familyTitle;
      break;
    case SurveyRoles.RESIDENT:
      title = question.residentTitle;
      break;
    case SurveyRoles.PROFESSIONAL:
      title = question.professionalTitle;
      break;
    default:
      return 'false';
  }

  let options;
  let spokenOptions = ''; // to avoid the voice speak "undefined"
  if (question.answerOptionsGroup) {
    options = question.answerOptionsGroup.answerOptions.map(
      (option) => ({
        option,
        displayName: option.title,
      }),
      {},
    );
    spokenOptions = options.map(({ displayName }) => displayName).join('. ');
  }

  const comment = watch('comment');
  return (
    <Row className="pt-5">
      <Col>
        <p className="display-4">
          {title}
          <Button
            className="ms-2 text-dark"
            variant="light"
            onClick={() => speak({ text: title + spokenOptions, voice: langVoices[langVoices.length - 1] })}
            disabled={speaking}
          >
            <FontAwesomeIcon icon={faVolumeUp} />
          </Button>
        </p>
      </Col>
      <Col xs={12} className="pt-5 justify-content-center">
        <Row>
          <div className="d-grid gap-1">
            <SurveyQuestionInput
              question={question}
              choice={choice}
              interviewee={interviewee}
              handleCheckboxSelection={handleCheckboxSelection}
              handleRadioSelection={handleRadioSelection}
              onTextInputSubmitted={onTextInputSubmitted}
              onRankingSelectionSubmitted={onRankingSelectionSubmitted}
              comment={comment}
              stopListeningComment={stop}
              gender={gender}
            />
            <Form.Control
              {...register('comment', { required: true })}
              as="textarea"
              lang="fr"
              rows={3}
              defaultValue={choice && choice.comment}
              placeholder={t(`survey.commentPlaceholder`)}
            />
            {supported && (
              <Button variant="info" size="lg" onClick={() => (listening ? stop() : listen({ lang: 'fr-FR' }))}>
                <FontAwesomeIcon icon={faMicrophone} />
              </Button>
            )}
            <Form.Text>{listening && <div>{t('campaigns.assessments.listening')}</div>}</Form.Text>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

SurveyQuestion.propTypes = {
  interviewee: surveyRolesType.isRequired,
  ...SurveyQuestionInput.PropTypes,
};

export default SurveyQuestion;
