import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Dashboard from './Dashboard';
import MyAccount from './MyAccount';
import ActionPlansRouter from './action_plans/ActionPlansRouter';
import CampaignAnalysisList from './campaigns/CampaignAnalysisList';
import CampaignRouter from './campaigns/CampaignRouter';
import NewCampaign from './campaigns/NewCampaign';

function Main() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/facilities/:facilityId/campaigns/new" element={<NewCampaign />} />
        <Route path="/action_plans/:actionPlanId/*" element={<ActionPlansRouter />} />
        <Route path="/campaigns/analysis" element={<CampaignAnalysisList />} />
        <Route path="/campaigns/:campaignId/*" element={<CampaignRouter />} />
        <Route path="/my_account" element={<MyAccount />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </MainLayout>
  );
}

export default Main;
