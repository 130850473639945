import PropTypes from 'prop-types';
import goodPracticeType from './goodPracticeType';

const actionPlanType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    goodPractice: goodPracticeType.isRequired,
  }))
});

export default actionPlanType;
