import QuestionTypes from './QuestionTypes';
import AssessmentRoles from './AssessmentRoles';

const RetirementHomeRelatedTypes = ["retirement_home", "senior_residence", "temporary_home", "day_care", "night_care", "ssr", "usld"];

const AssessmentQuestions = [

  // Données socio-démographiques

  {
    key: 'gender',
    type: QuestionTypes.RADIO,
    options: ['male', 'female'],
  },
  {
    key: 'yearOfBirth',
    type: QuestionTypes.NUMBER,
  },
  {
    key: 'educationalLevel',
    type: QuestionTypes.RADIO,
    options: Array.from(Array(8).keys()).map(k => k + 1),
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'job',
    type: QuestionTypes.TEXT,
  },
  {
    key: 'jobContract',
    type: QuestionTypes.RADIO,
    options: [
      "permanent",
      "temporary",
      "stand_in",
      "contractor",
      "interim",
      "other",
    ],
    precondition: (role) => role === AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'yearOfAdmission',
    type: QuestionTypes.NUMBER,
  },
  {
    key: 'jobSeniority',
    type: QuestionTypes.NUMBER,
    precondition: (role) => role === AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'maritalStatus',
    type: QuestionTypes.RADIO,
    options: [
      "married",
      "single",
      "couple",
      "divorced",
      "widowed",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'nationality',
    type: QuestionTypes.RADIO,
    options: [
      "french",
      "binational",
      "foreigner",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'nativeCountry',
    type: QuestionTypes.TEXT,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    condition: (answers) => answers.nationality !== "french",
  },
  {
    key: 'trusteeship',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
  },

  // Relations au sein de l'établissement

  {
    key: 'residentRelationship',
    type: QuestionTypes.TEXT,
    precondition: (role) => role === AssessmentRoles.CAREGIVER,
  },
  {
    key: 'cvsKnowledge',
    type: QuestionTypes.RADIO,
  },
  {
    key: 'isCvsMember',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'freedomCharter',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'freedomCharterSignatories',
    type: QuestionTypes.CHECKBOX,
    default: [],
    options: ['employee', 'customer', 'caregiver'],
    condition: (answers) => answers.freedomCharter === true,
    na_available: () => true,
  },
  {
    key: 'arrivalsInformation',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'residentFamiliesSufficientlyInvolved',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentFamiliesTooInvolved',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentFamillyRelationships',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentRelationships',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentInvolved',
    type: QuestionTypes.SCALE,
  },

  // Qualité de vie

  {
    key: 'isGoingToDayCenter',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'weeklyVisits',
    type: QuestionTypes.RADIO,
    options: [
      "excessive",
      "adequate",
      "insufficient",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'cognitiveDisorders',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'hearingDisorder',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'mobilityDisorders',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'visionDisorder',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'hasBeenHospitalized',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToStandUp',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToGoToTheToilets',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'useIncontinenceProducts',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToTakeShower',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToDoHair',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToDress',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'abilityToDress',
    type: QuestionTypes.SCALE,
    options: [
      "normal",
      "low",
      "none"
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToGoOut',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToGoShopping',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'needsAssistanceToCook',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'needsAssistanceToEat',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToTakeMedication',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasDailyMedication',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasGoneToHealthFacility',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'hasHomeCare',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'needsAssistanceToManageMoney',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'needsAssistanceToDoHousework',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role === AssessmentRoles.CUSTOMER && RetirementHomeRelatedTypes.includes(campaign.facility.facilityType),
  },
  {
    key: 'needsAssistanceToUseAPhone',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'abilityToDoDailyActivities',
    type: QuestionTypes.SCALE,
    options: [
      "normal",
      "low",
      "none"
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasSeenDoctor',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'currentHealthCondition',
    type: QuestionTypes.SCALE,
    options: [
      "perfect",
      "great",
      "good",
      "average",
      "low",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasBonePain',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasCancer',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasCardiovascularDisorders',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasChronicDiseases',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasDepression',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasDiabetes',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasDizziness',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasFracture',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasHearingProblems',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasUrineLoss',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasVisionProblems',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasProstateProblems',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'hasStrokes',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'memoryLoss',
    type: QuestionTypes.SCALE,
    options: [
      "no",
      "some",
      "severe",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'pain',
    type: QuestionTypes.SCALE,
    options: [
      "no",
      "some",
      "severe",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'feelingCalm',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "often",
      "sometimes",
      "rarely",
      "never",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'feelingDepressed',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "often",
      "sometimes",
      "rarely",
      "never",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'feelingHappy',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "often",
      "sometimes",
      "rarely",
      "never",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'feelingLow',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "often",
      "sometimes",
      "rarely",
      "never",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'feelingNervous',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "often",
      "sometimes",
      "rarely",
      "never",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'healthIssuesDisruptingSocialLife',
    type: QuestionTypes.SCALE,
    options: [
      "always",
      "never",
      "often",
      "rarely",
      "sometimes",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'healthConditionChange',
    type: QuestionTypes.SCALE,
    options: [
      "greatly_improved",
      "slightly_improved",
      "stable",
      "slightly_deteriorated",
      "greatly_deteriorated",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'abilityToWalk',
    type: QuestionTypes.SCALE,
    options: [
      "normal",
      "low",
      "none"
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'generalMood',
    type: QuestionTypes.SCALE,
    options: [
      "normal",
      "low",
      "depressed",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'qualityOfLifeBeforeFacilityAdmission',
    type: QuestionTypes.SCALE,
    options: [
      "perfect",
      "great",
      "good",
      "average",
      "low",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'qualityOfLifeSinceFacilityAdmission',
    type: QuestionTypes.SCALE,
    options: [
      "greatly_improved",
      "slightly_improved",
      "stable",
      "slightly_deteriorated",
      "greatly_deteriorated",
    ],
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },

  // Choix de vie

  {
    key: "chooseClothes",
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'chooseMeal',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'participativeMeals',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'mixUsers',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'servesWine',
    type: QuestionTypes.RADIO,
    na_available: (role) => role === AssessmentRoles.CAREGIVER,
  },
  {
    key: 'flexibleAwakeningBedtimeHours',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'flexibleCareSchedules',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'paidServices',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'chooseDoctor',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'choosenDoctorHelp',
    type: QuestionTypes.RADIO,
    condition: (answers) => answers.chooseDoctor === true,
    na_available: () => true,
  },
  {
    key: "buyClothes",
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },

  // Liberté d'aller et venir

  {
    key: 'reportAbsence',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'gpsTracking',
    type: QuestionTypes.RADIO,
  },
  {
    key: 'gpsTrackingChoice',
    type: QuestionTypes.RADIO,
    condition: (answers) => answers.gpsTracking === true,
    na_available: () => true,
  },
  {
    key: 'gpsTrackingAcceptanceReason',
    type: QuestionTypes.RADIO,
    options: [
      'security',
      'independence',
      'advice',
      'do_not_know',
      'no_choice',
    ],
    condition: (answers) => answers.gpsTrackingChoice === true,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'gpsTrackingRefusalReason',
    type: QuestionTypes.RADIO,
    options: [
      'no_point',
      'surveillance',
      'freedom',
      'do_not_know',
    ],
    condition: (answers) => answers.gpsTrackingChoice === false,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'digicodeSecuredAccess',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'shareAccessCodes',
    type: QuestionTypes.RADIO,
    condition: (answers) => answers.digicodeSecuredAccess === true,
    na_available: () => true,
  },
  {
    key: 'closeCollectivePlaces',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'reducedMobilityAssistance',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'reducedMobilityAssistanceNeedsHelp',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'reducedMobilityAssistanceHasHelp',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'openedAlzheimerUnit',
    type: QuestionTypes.RADIO,
    precondition: (role, campaign) => role !== AssessmentRoles.CUSTOMER && campaign.hasSecureFacility,
    na_available: () => true,
  },

  // Contention

  {
    key: 'bedGuards',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'bedGuardsOptional',
    type: QuestionTypes.RADIO,
    condition: (answers) => answers.bedGuards === true,
    na_available: () => true,
  },
  {
    key: 'overuseNeuroleptics',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'useContention',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'overuseContention',
    type: QuestionTypes.SCALE,
    condition: (answers) => answers.useContention === true,
  },

  // Se sentir chez soi

  {
    key: 'familyAccomodation',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'familyMeals',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
    na_available: () => true,
  },
  {
    key: "individualBedrooms",
    type: QuestionTypes.RADIO,
  },
  {
    key: "couplesAccommodation",
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: "externalCouplesAccommodation",
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: "introducePeople",
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: "introduceProfessionals",
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'diapersForEveryone',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'chooseDecoration',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'receiveRelativesComfortably',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'beautyServices',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },

  // Vie intime

  {
    key: 'trainedStaffOnOlderPeopleSexuality',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.EMPLOYEE,
  },
  {
    key: 'intimateRelationships',
    type: QuestionTypes.SCALE,
    na_available: () => true,
  },
  {
    key: 'areIntimateRelashionshipsPossible',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    na_available: () => true,
  },
  {
    key: 'speakAboutIntimacyWithFamilies',
    type: QuestionTypes.SCALE,
    precondition: (role) => role !== AssessmentRoles.CAREGIVER,
  },
  {
    key: 'speakAboutIntimacyWithResidents',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'speakAboutIntimacyWithProfessionals',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentsAdvanceDirectivesKnowledge',
    type: QuestionTypes.RADIO,
  },
  {
    key: 'advanceDirectives',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.EMPLOYEE,
    condition: (answers) => answers.residentsAdvanceDirectivesKnowledge === true,
    na_available: () => true,
  },
  {
    key: 'proAdvanceDirectivesKnowledge',
    type: QuestionTypes.RADIO,
    precondition: (role) => role !== AssessmentRoles.CUSTOMER,
  },
  {
    key: 'speakAboutDeathWithFamilies',
    type: QuestionTypes.SCALE,
    precondition: (role) => role !== AssessmentRoles.CAREGIVER,
  },
  {
    key: 'speakAboutDeathWithResidents',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'speakAboutDeathWithProfessionals',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'deathsInformation',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },

  // Droit aux risques

  {
    key: 'canRefuseTreatment',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'treatmentRefusalMeasures',
    type: QuestionTypes.TEXTAREA,
    condition: (answers) => answers.canRefuseTreatment === true,
    na_available: () => true,
  },
  {
    key: 'evaluationsInformation',
    type: QuestionTypes.RADIO,
  },

  // Accéder aux loisirs et à la culture


  {
    key: 'outdoorActivities',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'carService',
    type: QuestionTypes.RADIO,
    na_available: () => true,
  },
  {
    key: 'sharedMediaAccessCollection',
    type: QuestionTypes.CHECKBOX,
    default: [],
    options: ['tv', 'radio', 'newspapers', 'internet'],
  },
  {
    key: 'activitiesInformation',
    type: QuestionTypes.SCALE,
    na_available: () => true,
  },
  {
    key: 'cognitiveDisordersActivities',
    type: QuestionTypes.SCALE,
    precondition: (role) => role !== AssessmentRoles.CUSTOMER,
    na_available: () => true,
  },
  {
    key: 'facilityProjectInvolvement',
    type: QuestionTypes.SCALE,
    na_available: () => true,
  },
  {
    key: 'activitiesOrganisationConsultation',
    type: QuestionTypes.SCALE,
    na_available: () => true,
  },
  {
    key: 'isParticipatingInCommittees',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'isParticipatingInActivities',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },

  // Droit aux choix

  {
    key: 'respectFreedom',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'residentOpinionOnDecisionsAboutThem',
    type: QuestionTypes.SCALE,
  },
  {
    key: "consultResidentsBeforeEntering",
    type: QuestionTypes.SCALE,
    na_available: () => true,
  },
  {
    key: 'hasChosenNursingHomeSolution',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'nursingHomeChoiceReasons',
    type: QuestionTypes.TEXTAREA,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    condition: (answers) => answers.hasChosenNursingHomeSolution === true,
  },
  {
    key: 'nursingHomeDecisionMaker',
    type: QuestionTypes.TEXTAREA,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    condition: (answers) => answers.hasChosenNursingHomeSolution === false,
  },
  {
    key: 'isEnjoyingCurrentFacility',
    type: QuestionTypes.RADIO,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },
  {
    key: 'enjoyingCurrentFacilityReasons',
    type: QuestionTypes.TEXTAREA,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    condition: (answers) => answers.isEnjoyingCurrentFacility === true,
  },
  {
    key: 'notEnjoyingCurrentFacilityReasons',
    type: QuestionTypes.TEXTAREA,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
    condition: (answers) => answers.isEnjoyingCurrentFacility === false,
  },
  {
    key: 'isRecommendingFacility',
    type: QuestionTypes.SCALE,
    precondition: (role) => role === AssessmentRoles.CUSTOMER,
  },

  // Covid-19

  {
    key: 'covidContainmentIsolationFeeling',
    precondition: (role) => role !== AssessmentRoles.CAREGIVER,
    type: QuestionTypes.RADIO,
    default: [],
    options: ['very_isolated', 'isolated', 'unchanged', 'supported'],
  },
  {
    key: 'covidContainmentInformation',
    type: QuestionTypes.SCALE,
  },
  {
    key: 'covidContainmentNegativeImpacts',
    type: QuestionTypes.CHECKBOX,
    default: [],
    options: ['depression', 'isolation', 'memory_disorders', 'mobility_disorders'],
  },
  {
    key: 'covidContainmentPositiveImpacts',
    type: QuestionTypes.CHECKBOX,
    default: [],
    options: ['new_activities', 'professionals', 'social_interactions'],
  },
  {
    key: 'covidContainmentObstacles',
    type: QuestionTypes.CHECKBOX,
    precondition: (role) => role === AssessmentRoles.EMPLOYEE,
    default: [],
    options: ['equipments', 'professionals', 'management_support', 'public_authority_support'],
  },
  {
    key: 'covidContainmentDistantFeeling',
    type: QuestionTypes.CHECKBOX,
    precondition: (role) => role === AssessmentRoles.CAREGIVER,
    default: [],
    options: ['taken_badly', 'disproportionate', 'appropriate'],
  },

  // Aide pour remplir le questionnaire

  {
    key: 'helpToCompleteAssessment',
    type: QuestionTypes.SCALE,
  },
];

const filteredQuestions = (intervieweeRole, campaign) => AssessmentQuestions.filter((question) =>
  question.precondition === undefined || question.precondition(intervieweeRole, campaign)
);

const filteredQuestionsDefaults = (intervieweeRole, campaign) => filteredQuestions(intervieweeRole, campaign)
  .reduce((map, question) => {
    // eslint-disable-next-line no-param-reassign
    map[question.key] = question.default;
    return map;
  }, {});

export {
  AssessmentQuestions,
  filteredQuestions,
  filteredQuestionsDefaults,
};
