import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { campaignType } from '../../types';
import REPORT_CATEGORIES from '../../utils/ReportCategories';
import ActionsSelectionList from './ActionsSelectionList'


function NewActionPlan({ campaign }) {
  const { t } = useTranslation();
  const { report: { results, standardDeviations } } = campaign;

  const sortedResultsKeys = Object.keys(results)
    .filter((value) => Object.entries(REPORT_CATEGORIES).flatMap(entry => entry[1]).includes(value))
    .sort((a, b) => results[a] - results[b])
    .slice(0, 3)
    .reverse();

  const sortedDeviationsKeys = Object.keys(standardDeviations)
    .filter((value) => Object.entries(REPORT_CATEGORIES).flatMap(entry => entry[1]).includes(value))
    .sort((a, b) => standardDeviations[b] - standardDeviations[a])
    .slice(0, 3);

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Card.Title>{t('campaigns.actionPlan.new.title', { startDate: moment(campaign.createdAt).format('LL') })}</Card.Title>
              <Row>
                <Col xs={6}>
                  <strong>{t('campaigns.actionPlan.new.painPoints')}</strong>
                  <ul>
                    {sortedResultsKeys.map(category => (
                      <li key={category}>
                        {t(`campaigns.report.categories.${category}`)}
                      </li>
                      ))}
                  </ul>
                </Col>
                <Col xs={6}>
                  <strong>{t('campaigns.actionPlan.new.perceptionGaps')}</strong>
                  <ul>
                    {sortedDeviationsKeys.map(category => (
                      <li key={category}>
                        {t(`campaigns.report.categories.${category}`)}
                      </li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ActionsSelectionList campaign={campaign} />
    </>
  );
}

NewActionPlan.propTypes = {
  campaign: campaignType.isRequired,
};

export default NewActionPlan;
