import PropTypes from 'prop-types';

const goodPracticeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  durationMonths: PropTypes.number.isRequired,
  standardBudget: PropTypes.number.isRequired,
  fullTimeEquivalent: PropTypes.number.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  potentialResidentsImpact: PropTypes.number.isRequired,
  potentialProfessionalsImpact: PropTypes.number.isRequired,
});

export default goodPracticeType;
