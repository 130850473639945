import React, { useContext, useCallback } from 'react';
import {
  Row, Col, Card, Image,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import UserContext from '../contexts/UserContext';
import BlankProfilePicture from '../../images/blank-profile-picture.png';

function MyAccount() {
  const { t } = useTranslation();
  const { name, email, avatar_url: avatarUrl} = useContext(UserContext);
  // eslint-disable-next-line no-unused-vars
  const onDrop = useCallback((_acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('header.myAccount')}</h1>
        <Card>
          <Card.Body>
            <Row>
              <Col md={10}>
                <h3>{name}</h3>
                <dl>
                  <dt>{t('account.email')}</dt>
                  <dd>{email}</dd>
                </dl>
              </Col>
              <Col md={2}>
                <div {...getRootProps() /* eslint-disable-line react/jsx-props-no-spreading */}>
                  <input {...getInputProps() /* eslint-disable-line react/jsx-props-no-spreading */} />
                  <Image
                    alt="user avatar"
                    src={avatarUrl || BlankProfilePicture}
                    className="d-inline-block align-top"
                    fluid
                  />
                  {
                    isDragActive
                      ? <p>{t('account.dropFileHere')}</p>
                      : <p>{t('account.dropOrSelectFile')}</p>
                  }
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default MyAccount;
