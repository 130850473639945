import { useQuery } from '@apollo/client';
import { faBuilding, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Code } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import FullPageLayout from '../../layouts/FullPageLayout';
import { FacilitiesQuery } from '../../operations.graphql';
import StepCreateFacilityForm from './StepCreateFacilityForm';
import StepCreateGroupForm from './StepCreateGroupForm';
import StepOtherOptionOnboarding from './StepOtherOptionOnboarding';

function CreateStructures({ selectedRole }) {
  switch (selectedRole) {
    case 'facilityDirector':
      return <StepCreateFacilityForm />;
    case 'groupManager':
      return <StepCreateGroupForm />;
    default:
      return <StepOtherOptionOnboarding />;
  }
}

CreateStructures.propTypes = {
  selectedRole: PropTypes.string.isRequired,
};

function ChooseRole({ onRoleSelected }) {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.chooseRoleTitle')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('onboarding.chooseRoleDescription')}</p>
            <div className="d-grid gap-2">
              <Button variant="outline-dark" onClick={() => onRoleSelected('facilityDirector')}>
                <h2>
                  <FontAwesomeIcon className="mr-2" icon={faUserTie} />
                </h2>
                {t('onboarding.facilityDirector')}
              </Button>
              <Button variant="outline-dark" onClick={() => onRoleSelected('groupManager')}>
                <h2>
                  <FontAwesomeIcon className="mr-2" icon={faBuilding} />
                </h2>
                {t('onboarding.groupManager')}
              </Button>
              <Button variant="outline-dark" onClick={() => onRoleSelected('other')}>
                {t('onboarding.other')}
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

ChooseRole.propTypes = {
  onRoleSelected: PropTypes.func.isRequired,
};

function OnboardingStep() {
  const [selectedRole, setSelectedRole] = useState(undefined);

  if (!selectedRole) {
    return <ChooseRole onRoleSelected={(role) => setSelectedRole(role)} />;
  }

  return <CreateStructures selectedRole={selectedRole} />;
}

function Onboarding() {
  const { loading, data } = useQuery(FacilitiesQuery);
  const { t } = useTranslation();

  if (loading) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('onboarding.chooseRoleTitle')}</h1>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Code />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  if (Array.isArray(data.facilities) && data.facilities.length) {
    return <Navigate to="/" />;
  }

  return (
    <FullPageLayout variant="gradient-primary">
      <Row className="min-h-100">
        <Col className="md-8 text-dark min-h-100">
          <div className="d-flex flex-column align-items-center">
            <div className="mt-5" style={{ width: '425px' }}>
              <OnboardingStep />
            </div>
          </div>
        </Col>
      </Row>
    </FullPageLayout>
  );
}

export default Onboarding;
