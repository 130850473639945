import React from 'react';
import PropTypes from 'prop-types';

function FullPageLayout({ children, variant }) {
  return <div className={`bg-${variant} text-white full-page`}>
    {children}
  </div>
}

FullPageLayout.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
};

FullPageLayout.defaultProps = {
  children: null,
  variant: 'dark',
};

export default FullPageLayout;
