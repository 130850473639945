import React from 'react';
import { useQuery } from '@apollo/client';
import { Row, Col, Card } from 'react-bootstrap';
import { Code } from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import CampaignCard from './CampaignCard';
import CampaignStatus from './CampaignStatus';
import { SearchCampaigns } from '../../operations.graphql';

function CampaignAnalysisList() {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(SearchCampaigns, {
    variables: {
      filter: {
        state: 'data_analysis',
      },
    },
  });

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <Code />
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <Card.Title>{t('campaigns.dataAnalysis.error')}</Card.Title>
        <Card.Body>
          <Card.Text>error</Card.Text>
        </Card.Body>
      </Card>
    );
  }

  const { campaigns } = data;

  if (campaigns.length === 0) {
    return (
      <div className="jumbotron">
        <h1 className="display-4">{t('campaigns.dataAnalysis.title')}</h1>
        <p className="lead">{t('campaigns.dataAnalysis.noCampaign')}</p>
      </div>
    );
  }

  return campaigns.map((campaign, index) => (
    <Row key={campaign.id}>
      <Col className="pb-3">
        <CampaignCard
          campaign={campaign}
          index={campaigns.length - index}
          title={t(`campaigns.${campaign.campaignState === CampaignStatus.FINISHED ? 'finished' : 'active'}.title`, {
            index: campaigns.length - index,
            startDate: moment(campaign.createdAt).format('LL'),
            endDate: moment(campaign.finishedAt).format('LL'),
          })}
        />
      </Col>
    </Row>
  ));
}

export default CampaignAnalysisList;
