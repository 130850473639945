import PropTypes from 'prop-types';
import facilityType from './facilityType';

const campaignReportType = PropTypes.shape({
  freedomAndSecurityTheme: PropTypes.number,
  respectForLifestyle: PropTypes.number,
  connectionWithTheOutside: PropTypes.number,
  personalizedSupport: PropTypes.number,
  accessibilityOfCollectivePlaces: PropTypes.number,
  contention: PropTypes.number,
  mobilityAid: PropTypes.number,
  chooseAndOwnYourPlaceTheme: PropTypes.number,
  fosterResidentsRelationshipsWithFamilies: PropTypes.number,
  mixedPopulations: PropTypes.number,
  fosterResidentsRelationshipsWithLovedOnes: PropTypes.number,
  residentsWelcome: PropTypes.number,
  consent: PropTypes.number,
  consumptionOfEverydayGoods: PropTypes.number,
  chooseHowToBeHelpedTheme: PropTypes.number,
  respectForTheRhythmOfLife: PropTypes.number,
  respectForAutonomy: PropTypes.number,
  choiceOfAccompaniment: PropTypes.number,
  adaptationOfTheLivingSpace: PropTypes.number,
  welcomingFamilies: PropTypes.number,
  haveControlYourOwnBodyTheme: PropTypes.number,
  respectForWishes: PropTypes.number,
  adaptationToNeeds: PropTypes.number,
  respectForDeath: PropTypes.number,
  respectForPrivacy: PropTypes.number,
  adaptationToVulnerabilities: PropTypes.number,
  getInformedToStayUsefulTheme: PropTypes.number,
  inclusionInDecisions: PropTypes.number,
  informationOnTheLifeOfTheEstablishment: PropTypes.number,
  accessLeisureAndCultureTheme: PropTypes.number,
  openingOfTheStructureToTheOutside: PropTypes.number,
  openingOfTheStructure: PropTypes.number,
  informationOnActivities: PropTypes.number,
  adaptationOfActivities: PropTypes.number,
  residentConsultation: PropTypes.number,
  facilityRelationshipsTheme: PropTypes.number,
  feelingOfRespectForFreedoms: PropTypes.number,
  familyInvolvement: PropTypes.number,
  establishLastingRelationshipsAndTrust: PropTypes.number,
  participation: PropTypes.number,
});

const campaignType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  campaignState: PropTypes.string.isRequired,
  professionalsAnswered: PropTypes.bool.isRequired,
  customersAnswered: PropTypes.bool.isRequired,
  caregiversAnswered: PropTypes.bool.isRequired,
  currentUserAnswered: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  finishedAt: PropTypes.string,
  assessmentSummary: campaignReportType,
  professionalsAnswerRate: PropTypes.number,
  customersAnswerRate: PropTypes.number,
  caregiversAnswerRate: PropTypes.number,
  useNewTemplate: PropTypes.bool.isRequired,
  facility: facilityType,
});

export default campaignType;
