import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CreateCaregiverAssessment, CreateCustomerAssessment, CreateEmployeeAssessment } from '../../operations.graphql';
import { assessmentRolesType } from '../../types';
import AssessmentRoles from './AssessmentRoles';

function getMutationFor(role) {
  switch (role) {
    case AssessmentRoles.CAREGIVER:
      return CreateCaregiverAssessment;
    case AssessmentRoles.CUSTOMER:
      return CreateCustomerAssessment;
    case AssessmentRoles.EMPLOYEE:
      return CreateEmployeeAssessment;
    default:
      return null;
  }
}

function AssessmentFormSubmit({ campaignId, assessmentAttributes, intervieweeRole, selfAssessment, token }) {
  const { t } = useTranslation();
  const mutation = getMutationFor(intervieweeRole);
  const [createAssessment, { loading, data, errors }] = useMutation(mutation);

  if (errors) {
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <p className="text-center text-danger">{errors}</p>
          </Col>
        </Row>
      </Container>
    );
  }

  if (data) {
    if (token) localStorage.removeItem(`CampaignInvitationRole-${token}`);
    localStorage.removeItem(`assessmentFormState-${token || campaignId}-${intervieweeRole}`);
    localStorage.removeItem(`assessmentFormAnswers-${token || campaignId}-${intervieweeRole}`);
    return (
      (token && (
        <Container>
          <Row>
            <Col xs={12} className="mt-5">
              <h1>{t(`campaigns.assessments.submitConfirmation.title`)}</h1>
              <p className="lead">
                <Trans i18nKey="multiline">{t(`campaigns.assessments.submitConfirmation.body`)}</Trans>
              </p>
            </Col>
          </Row>
        </Container>
      )) || <Navigate to={`/campaigns/${campaignId}/assessments/new`} />
    );
  }

  return (
    <Container>
      <Row>
        <Col xs={12} className="mt-5">
          <div className="d-grid gap-1">
            <h1>{t(`campaigns.assessments.submitForm.title`)}</h1>
            <p className="lead">
              <Trans i18nKey="multiline">{t(`campaigns.assessments.submitForm.privacy`)}</Trans>
            </p>
            <Button
              className="mt-3"
              onClick={loading ? null : () =>
                createAssessment({
                  variables: {
                    campaignId,
                    inviteToken: token,
                    assessmentAttributes: {
                      selfAssessment,
                      intervieweeRole,
                      ...assessmentAttributes,
                    },
                  },
                })
              }
              disabled={loading}
            >
              {t(`campaigns.assessments.submitForm.submit`)}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

AssessmentFormSubmit.propTypes = {
  campaignId: PropTypes.string.isRequired,
  assessmentAttributes: PropTypes.shape({}).isRequired,
  intervieweeRole: assessmentRolesType.isRequired,
  selfAssessment: PropTypes.bool,
  token: PropTypes.string,
};

AssessmentFormSubmit.defaultProps = {
  selfAssessment: false,
  token: undefined,
};

export default AssessmentFormSubmit;
