import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SwitchTransition } from 'react-transition-group';
import { assessmentRolesType, campaignType } from '../../types';
import usePersistedState from '../../utils/usePersistedState';
import SlideTransition from '../SlideTransition';
import AssessmentFormSubmit from './AssessmentFormSubmit';
import AssessmentQuestion from './AssessmentQuestion';
import { filteredQuestions, filteredQuestionsDefaults } from './AssessmentQuestions';
import QuestionTypes from './QuestionTypes';

const TransitionDirections = {
  LEFT: 'left',
  RIGHT: 'right',
};

function LegacyAssessmentForm({ campaign, intervieweeRole, selfAssessment, timeout, wrongRoleButton, token }) {
  const { t } = useTranslation();
  const [seconds, setSeconds] = usePersistedState(`assessmentFormSeconds-${token || campaign.id}-${intervieweeRole}`, 0);
  const [questionSeconds, setQuestionSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(() => seconds + 1);
      setQuestionSeconds(() => questionSeconds + 1);
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, questionSeconds]);

  const [state, setState] = usePersistedState(`assessmentFormState-${token || campaign.id}-${intervieweeRole}`, {
    step: 0,
    transitionDirection: TransitionDirections.RIGHT,
  });

  const questions = filteredQuestions(intervieweeRole, campaign);
  const defaults = filteredQuestionsDefaults(intervieweeRole, campaign);
  const [answers, setAnswers] = usePersistedState(`assessmentFormAnswers-${token || campaign.id}-${intervieweeRole}`, {
    ...defaults,
  });
  const [questionsTimings, setQuestionsTimings] = usePersistedState(`assessmentFormTimings-${token || campaign.id}-${intervieweeRole}`, {});

  const { step, transitionDirection } = state;
  const questionsCount = questions.length;

  if (step === questionsCount) {
    ReactGA.timing({
      category: 'Assessments',
      variable: intervieweeRole,
      value: seconds * 1000, // in milliseconds
      label: 'full assessment',
    });
    Object.keys(questionsTimings).forEach((key) => {
      ReactGA.timing({
        category: 'Assessments',
        variable: intervieweeRole,
        value: questionsTimings[key] * 1000, // in milliseconds
        label: key,
      });
    });
    return (
      <AssessmentFormSubmit
        campaignId={campaign.id}
        assessmentAttributes={answers}
        intervieweeRole={intervieweeRole}
        selfAssessment={selfAssessment}
        token={token}
      />
    );
  }

  const question = questions[step];
  const { key, type } = question;
  const { gender } = answers;

  const nextStep = () => {
    // eslint-disable-next-line no-shadow
    const { step } = state;
    setState({
      step: step + 1,
      transitionDirection: TransitionDirections.RIGHT,
    });
    setQuestionsTimings({
      ...questionsTimings,
      [key]: questionSeconds,
    });
    setQuestionSeconds(0);
  };

  const prevStep = () => {
    // eslint-disable-next-line no-shadow
    const { step } = state;
    setState({
      step: step - 1,
      transitionDirection: TransitionDirections.LEFT,
    });
    setQuestionSeconds(questionsTimings[questions[step - 1].key]);
  };

  // eslint-disable-next-line no-shadow
  const handleRadioSelection = (key, value) => {
    setAnswers({
      ...answers,
      [key]: value,
    });
    setTimeout(nextStep, timeout);
  };

  // eslint-disable-next-line no-shadow
  const handleCheckboxSelection = (key, value) => {
    let val = answers[key] || [];
    if (val.includes(value)) {
      val = val.filter((v) => v !== value);
    } else {
      val.push(value);
    }
    setAnswers({
      ...answers,
      [key]: val,
    });
  };

  // eslint-disable-next-line no-shadow
  const onTextInputSubmitted = (key, value) => {
    setAnswers({
      ...answers,
      [key]: value,
    });
    nextStep();
  };

  let options;
  let spokenOptions;
  if (question.options) {
    options = question.options.map(
      (option) => ({
        option,
        displayName: t(`attributes.assessment.${pluralize(key)}.${option}`, { context: gender }),
      }),
      {},
    );
    spokenOptions = options.map(({ displayName }) => displayName).join('. ');
  } else if (QuestionTypes.options[type]) {
    const opts = QuestionTypes.options[type];
    if (type === QuestionTypes.RADIO && question.na_available !== undefined && question.na_available(intervieweeRole) && opts[opts.length - 1] !== null) {
      opts.push(null);
    }
    options = opts.map(
      (option) => ({
        option,
        displayName: t(`attributes.assessment.defaults.${option}`, { context: gender }),
      }),
      {},
    );
    spokenOptions = type === QuestionTypes.SCALE ? t('campaigns.assessments.new.scaleOptions') : options.map(({ displayName }) => displayName).join('. ');
  }

  const val = answers[key];
  const progress = (step / questionsCount) * 100;
  const showNextButton = val !== undefined && (question.type === QuestionTypes.CHECKBOX || transitionDirection === TransitionDirections.LEFT);

  if (question.condition && !question.condition(answers)) {
    if (transitionDirection === TransitionDirections.RIGHT) {
      nextStep();
    } else {
      prevStep();
    }
  }

  return (
    <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
      <Row>
        <Col>
          <Row>
            <Col xs={12}>
              <h1>{(selfAssessment && t(`campaigns.assessments.self.title`)) || t(`campaigns.assessments.${intervieweeRole}.title`)}</h1>
            </Col>
            <Col xs={12} className="mt-3">
              {t('campaigns.assessments.progress')}
              <ProgressBar now={progress} label={`${Math.floor(progress)}%`} visuallyHidden />
            </Col>
          </Row>

          <div className={transitionDirection}>
            <SwitchTransition>
              <SlideTransition key={key}>
                <AssessmentQuestion
                  type={type}
                  value={val}
                  name={key}
                  options={options}
                  spokenOptions={spokenOptions}
                  interviewee={intervieweeRole}
                  handleCheckboxSelection={handleCheckboxSelection}
                  handleRadioSelection={handleRadioSelection}
                  onTextInputSubmitted={onTextInputSubmitted}
                  gender={gender}
                />
              </SlideTransition>
            </SwitchTransition>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="float-start">
            {step > 0 && (
              <Button variant="link" onClick={prevStep}>
                {t(`campaigns.assessments.${intervieweeRole}.back`)}
              </Button>
            )}
            {step === 0 &&
              transitionDirection === 'right' &&
              (wrongRoleButton || (
                <small>
                  <Link to={`/campaigns/${campaign.id}/assessments/new`}>{t('campaigns.assessments.wrongRole')}</Link>
                </small>
              ))}
          </div>
          <div className="float-end">{showNextButton && <Button onClick={nextStep}>{t(`campaigns.assessments.${intervieweeRole}.next`)}</Button>}</div>
        </Col>
      </Row>
    </Container>
  );
}

LegacyAssessmentForm.propTypes = {
  intervieweeRole: assessmentRolesType.isRequired,
  selfAssessment: PropTypes.bool,
  timeout: PropTypes.number,
  campaign: campaignType.isRequired,
  wrongRoleButton: PropTypes.node,
  token: PropTypes.string,
};

LegacyAssessmentForm.defaultProps = {
  selfAssessment: false,
  timeout: 200,
  wrongRoleButton: undefined,
  token: undefined,
};

export default LegacyAssessmentForm;
