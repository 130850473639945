import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

import Header from '../components/Header';

function MainLayout({ children }) {
  return <>
    <Header />
    <main className="main-content py-3" role="main">
      <Container fluid>
        <Row>
          <Col>
            {children}
          </Col>
        </Row>
      </Container>
    </main>
  </>
}

MainLayout.propTypes = {
  children: PropTypes.node,
};

MainLayout.defaultProps = {
  children: null,
};

export default MainLayout;
