import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';

function EmptyCampaigns({ facilityId }) {
  const { t } = useTranslation();
  return (
    <div className="jumbotron">
      <h1 className="display-4">{t('dashboard.assessments.index.welcome')}</h1>
      <p>
        {t('dashboard.assessments.index.introduction')}
        <br />
        {t('dashboard.assessments.index.securedData')}
      </p>
      <hr className="my-4" />
      <p className="lead">{t('dashboard.assessments.index.onboarding')}</p>
      <div className="d-grid gap-1">
        <LinkContainer to={`/facilities/${facilityId}/campaigns/new`}>
          <Button variant="primary" size="lg">
            {t('dashboard.assessments.index.newCampaign')}
          </Button>
        </LinkContainer>
      </div>
    </div>
  );
}

EmptyCampaigns.propTypes = {
  facilityId: PropTypes.string.isRequired,
};

export default EmptyCampaigns;
