import $ from 'jquery';
import 'select2';

function validateEmail(email) {
  // eslint-disable-next-line max-len
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

function initSelects() {
  $('.select2').select2({
    theme: 'bootstrap-5',
    width: 'style',
  });

  $('.tokenized').select2({
    theme: 'bootstrap-5',
    width: 'style',
    tags: true,
    tokenSeparators: [','],
  });

  $('.tokenized-emails')
    .select2({
      theme: 'bootstrap-5',
      width: 'style',
      tags: true,
      tokenSeparators: [',', ' '],
      minimumResultsForSearch: 1,
      selectOnClose: true,
      createTag: (searchOption) => {
        const { term } = searchOption;
        if (validateEmail(term)) {
          return {
            id: term,
            text: term,
          };
        }
        return null;
      },
    })
    .on('select2:open', () => {
      $('.select2-container--open .select2-dropdown--below').css('display', 'none');
    });
}

$(document).on('turbolinks:before-cache', () => {
  $('.select2-hidden-accessible').select2('destroy');
});

$(document).on('turbolinks:load', () => {
  initSelects();
});
