import { faArrowRight, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, ButtonToolbar, Col, Container, Form, InputGroup, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSpeechRecognition } from 'react-speech-kit';
import questionType from '../../types/questionType';

function SurveyQuestionInput({
  question,
  choice,
  handleCheckboxSelection,
  handleRadioSelection,
  onRankingSelectionSubmitted,
  onTextInputSubmitted,
  comment,
  stopListeningComment,
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,

    formState: { errors },
  } = useForm({
    defaultValues: {
      [question.id]: (choice && choice.value) || '',
    },
  });
  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult: (result) => {
      setValue(question.id, result);
    },
  });
  const [rankingOrder, setRankingOrder] = useState([]);

  switch (question.kind) {
    case 'radio':
      return question.answerOptionsGroup.answerOptions.map(({ id, title, value }) => (
        <Col className="mb-4" key={value}>
          <div className="d-grid">
            <Button
              size="lg"
              className="text-dark fw-bold"
              value={value}
              active={choice && choice.answerOptionIds.includes(id)}
              onClick={() => {
                stopListeningComment();
                handleRadioSelection(question.id, id, comment);
              }}
            >
              {title}
            </Button>
          </div>
        </Col>
      ));
    case 'ranking': {
      let newRanking =
        rankingOrder.length === 0
          ? question.answerOptionsGroup.answerOptions.slice(0).map((ao) => ({ id: ao.id, title: ao.title, value: ao.value }))
          : rankingOrder;
      newRanking = newRanking.sort((a, b) => a.value - b.value);
      return (
        <Container fluid className="mb-4">
          {newRanking.map(({ id, title, value }) => (
            <Container fluid className="mb-4" key={id}>
              <div className="d-grid">
                <Row>
                  <Col lg>
                    <div className="d-grid">
                      <Button size="lg" className="text-dark fw-bold" value={value}>
                        {title}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="auto">
                    <Form.Select
                      value={value}
                      size="lg"
                      onChange={(event) => {
                        newRanking.splice(event.nativeEvent.target.value - 1, 1, Object.assign(newRanking[event.nativeEvent.target.value - 1], { value }));
                        newRanking.splice(value - 1, 1, Object.assign(newRanking[value - 1], { value: event.nativeEvent.target.value }));
                        setRankingOrder(() => newRanking);
                      }}
                    >
                      {[...Array(newRanking.length).keys()].map((i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </div>
            </Container>
          ))}
          <div className="d-grid">
            <Row md="auto" className="justify-content-md-end">
              <Button
                variant="primary"
                onClick={() => {
                  stopListeningComment();
                  onRankingSelectionSubmitted(
                    question.id,
                    (rankingOrder.length === 0 ? newRanking : rankingOrder).map((e) => ({ answerOptionId: e.id, order: parseInt(e.value, 10) })),
                    comment,
                  );
                }}
              >
                {t(`survey.next`)}
              </Button>
            </Row>
          </div>
        </Container>
      );
    }
    case 'checkbox':
      return question.answerOptionsGroup.answerOptions.map(({ id, title, value }) => (
        <Col key={value} className="mb-4">
          <div className="d-grid gap-1">
            <Button
              size="lg"
              className="text-dark fw-bold custom-control custom-checkbox"
              value={value}
              onClick={() => {
                stopListeningComment();
                handleCheckboxSelection(question.id, id, comment);
              }}
            >
              <input
                type="checkbox"
                id="custom-checkbox"
                className="custom-control-input"
                checked={((choice && choice.answerOptionIds) || []).includes(id)}
                onChange={() => {
                  stopListeningComment();
                  handleCheckboxSelection(question.id, id, comment);
                }}
                style={{ display: 'none' }}
              />
              <div className="custom-control-label">{title}</div>
            </Button>
          </div>
        </Col>
      ));
    case 'scale':
      return (
        <Col sm={12} className="mb-4 d-flex justify-content-center">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name={question.id} /* defaultValue={choice.answerOptionIds[0]} */>
              {Array(question.scaleRange.max + 1) // (0..10(+1)) => [0..11]
                .fill(question.scaleRange.max + 1)
                .map((_, i) => (
                  <ToggleButton
                    size="lg"
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    className="text-dark fw-bold"
                    value={i}
                    // active={choice && choice.answerOptionIds.includes(id)}
                    onClick={() => {
                      stopListeningComment();
                      onTextInputSubmitted(question.id, i, comment);
                    }}
                  >
                    {i}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </ButtonToolbar>
        </Col>
      );
    case 'textarea':
    case 'number':
    case 'text':
    default: {
      const val = watch(question.id);

      const onSubmit = (formData) => {
        let data = formData[question.id];
        if (question.kind === 'number') {
          data = parseInt(data, 10);
        }
        if (listening) {
          stop();
        }
        stopListeningComment();
        onTextInputSubmitted(question.id, data, comment);
      };

      return (
        <Col xs={12} className="mb-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <InputGroup>
                {supported && (
                  <Button
                    variant="info"
                    size="lg"
                    onClick={() => {
                      if (listening) {
                        stop();
                      } else {
                        stopListeningComment();
                        listen({ lang: 'fr-FR' });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faMicrophone} />
                  </Button>
                )}
                <Form.Control
                  {...register(`${question.id}`, { required: true })}
                  size="lg"
                  // placeholder={t(`campaigns.assessments.${interviewee}.${question.id}`, { context: gender })}
                  type={question.kind}
                  lang="fr"
                  isInvalid={!!errors[question.id]}
                />
                <Form.Control.Feedback type="invalid" className="order-last">
                  {errors[question.id] && 'Veuillez répondre la question'}
                </Form.Control.Feedback>
                <Button variant="primary" type="submit" size="lg" disabled={!val}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </InputGroup>
              <Form.Text>{listening && <div>{t('campaigns.assessments.listening')}</div>}</Form.Text>
            </Form.Group>
          </Form>
        </Col>
      );
    }
  }
}

SurveyQuestionInput.propTypes = {
  // type: PropTypes.oneOf([QuestionTypes.CHECKBOX, QuestionTypes.RADIO, QuestionTypes.TEXT, QuestionTypes.TEXTAREA, QuestionTypes.SCALE, QuestionTypes.NUMBER])
  //   .isRequired,
  // name: PropTypes.string.isRequired,
  question: questionType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  choice: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.any),
  comment: PropTypes.string,
  handleCheckboxSelection: PropTypes.func.isRequired,
  handleRadioSelection: PropTypes.func.isRequired,
  onRankingSelectionSubmitted: PropTypes.func.isRequired,
  onTextInputSubmitted: PropTypes.func.isRequired,
  stopListeningComment: PropTypes.func.isRequired,
};

SurveyQuestionInput.defaultProps = {
  options: undefined,
  choice: undefined,
  comment: undefined,
};

export default SurveyQuestionInput;
