import PropTypes from 'prop-types';

const facilityTypeType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  enabled: PropTypes.bool.isRequired,
});

const facilityType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  legalStatus: PropTypes.string.isRequired,
  facilityType: facilityTypeType,
});

export default facilityType;
