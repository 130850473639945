const CampaignStatus = {
  STARTED: 'started',
  DATA_COLLECTION: 'data_collection',
  WAITING_FOR_DATA_ANALYSIS: 'waiting_for_data_analysis',
  DATA_ANALYSIS: 'data_analysis',
  REPORT_PUBLISHED: 'report_published',
  FINISHED: 'finished',
};

export default CampaignStatus;
