import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Code } from 'react-content-loader';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CreateFacility, FacilitiesQuery, FacilityTypesQuery } from '../../operations.graphql';

function StepCreateFacilityForm() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const [facilityGroup, setFacilityGroup] = useState();
  const { loading: queryLoading, error, data } = useQuery(FacilityTypesQuery);
  const [createFacility, { loading, data: createFacilityData }] = useMutation(CreateFacility, {
    // eslint-disable-next-line no-shadow
    update(cache, { data: { createFacility } }) {
      const facilitiesCache = cache.readQuery({ query: FacilitiesQuery });
      cache.writeQuery({
        query: FacilitiesQuery,
        data: {
          facilities: [...facilitiesCache.facilities, createFacility.facility],
        },
      });
    },
  });

  const onSubmit = ({ facility }) => {
    createFacility({
      variables: {
        facility,
      },
    });
  };

  if (createFacilityData) {
    return <Navigate to="/" />;
  }

  if (error) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('onboarding.createFacility.title')}</h1>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Body>{error.message}</Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  if (queryLoading) {
    return (
      <Row>
        <Col xs={12}>
          <h1>{t('onboarding.createFacility.title')}</h1>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Code />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  const { facilityTypes } = data;

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.createFacility.title')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('onboarding.createFacility.description')}</p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-grid gap-1">
                <Form.Group controlId="groupFacilityName">
                  <Form.Label>{t('attributes.facility.name')}</Form.Label>
                  <Form.Control
                    {...register('facility[name]', { required: true })}
                    type="text"
                    placeholder={t('onboarding.placeholders.facilityName')}
                    isInvalid={!!errors['facility[name]']}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors['facility[name]'] && t('errors.models.facility.attributes.name.required')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="groupFacilityType">
                  <Form.Label>{t('attributes.facility.facilityType')}</Form.Label>
                  <Form.Control {...register('facility[facilityTypeId]', { required: true })} as="select" isInvalid={!!errors['facility[facilityTypeId]']}>
                    {facilityTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors['facility[facilityTypeId]'] && t('errors.models.facility.attributes.facilityType.required')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="facilityLegalStatus">
                  <Form.Label>{t('attributes.facility.legalStatus')}</Form.Label>
                  <Form.Control {...register('facility[legalStatus]', { required: true })} as="select" isInvalid={!!errors['facility[legalStatus]']}>
                    {['public_corporation', 'private_corporation', 'nonprofit_corporation'].map((status) => (
                      <option key={status} value={status}>
                        {t(`attributes.facility.legalStatuses.${status}`)}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors['facility[legalStatus]'] && t('errors.models.facility.attributes.legalStatus.required')}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="facilityBelongsToGroup">
                  <Form.Check type="checkbox" onChange={(e) => setFacilityGroup(e.target.checked)} label={t('onboarding.createFacility.belongsToGroup')} />
                </Form.Group>
                {facilityGroup && (
                  <Form.Group controlId="groupName">
                    <Form.Label>{t('attributes.group.name')}</Form.Label>
                    <Form.Control
                      {...register('facility[groupName]', { required: true })}
                      type="text"
                      placeholder={t('onboarding.placeholders.groupName')}
                      isInvalid={!!errors['facility[groupName]']}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['facility[groupName]'] && t('errors.models.group.attributes.name.required')}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Button className="mt-1" type="submit" disabled={loading}>
                  {t('onboarding.createFacility.submit')}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default StepCreateFacilityForm;
