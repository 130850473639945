// client
import { ApolloClient, InMemoryCache, ApolloLink, Observable, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

// getToken from meta tags
const getToken = () => document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const setTokenForOperation = async (operation) => operation.setContext({
  headers: {
    'X-CSRF-Token': getToken(),
  },
});

// link with token
const createLinkWithToken = () => new ApolloLink(
  (operation, forward) => new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then(setTokenForOperation)
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));
    return () => {
      if (handle) handle.unsubscribe();
    };
  }),
);

// log erors
// eslint-disable-next-line no-console
const logError = (error) => console.error(error);

// create error link
const createErrorLink = () => onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    const authenticationError = graphQLErrors.filter(
      error => error.extensions && error.extensions.code === "AUTHENTICATION_ERROR"
    );
    if (authenticationError.length > 0) {
      window.location.replace("/users/sign_in");
    } else {
      logError('GraphQL - Error', {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables,
      });
    }
  }
  if (networkError) {
    logError('GraphQL - NetworkError', networkError);
  }
});

// http link
const createHttpLink = () => new HttpLink({
  uri: '/graphql',
  credentials: 'include',
});

export const createCache = () => {
  const cache = new InMemoryCache();
  if (process.env.NODE_ENV === 'development') {
    window.secretVariableToStoreCache = cache;
  }
  return cache;
};

export const createClient = (cache /* , requestLink */) => new ApolloClient({
  link: ApolloLink.from([
    createErrorLink(),
    createLinkWithToken(),
    createHttpLink(),
  ]),
  cache,
});
