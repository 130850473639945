import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { assessmentRolesType, campaignType } from '../../types';
import Loading from '../Loading';
import AssessmentRoles from './AssessmentRoles';
import LegacyAssessmentForm from './LegacyAssessmentForm';
import Survey from './Survey';
import SurveyRoles from './SurveyRoles';
import { SurveyTemplateQuery } from '../../operations.graphql';

function LoadSurvey({ campaign, intervieweeRole, selfAssessment }) {
  const campaignId = campaign.id;

  const { loading, error, data } = useQuery(SurveyTemplateQuery, {
    variables: { campaignId },
  });

  if (error) {
    return (
      <Container className="d-flex flex-column justify-content-between p-3 min-vh-100 p-3">
        <Row>
          <Col xs={12}>
            <p>{error}</p>
          </Col>
        </Row>
      </Container>
    );
  }

  if (loading) {
    return <Loading />;
  }

  const { surveyTemplate } = data;
  return <Survey campaign={campaign} surveyTemplate={surveyTemplate} intervieweeRole={intervieweeRole} selfAssessment={selfAssessment} />;
}

LoadSurvey.propTypes = {
  intervieweeRole: assessmentRolesType.isRequired,
  selfAssessment: PropTypes.bool,
  campaign: campaignType.isRequired,
};

LoadSurvey.defaultProps = {
  selfAssessment: false,
};

function AssessmentForm({ campaign, intervieweeRole, selfAssessment }) {
  if (campaign.useNewTemplate) {
    let role;
    switch (intervieweeRole) {
      case AssessmentRoles.EMPLOYEE:
        role = SurveyRoles.PROFESSIONAL;
        break;
      case AssessmentRoles.CUSTOMER:
        role = SurveyRoles.RESIDENT;
        break;
      case AssessmentRoles.CAREGIVER:
        role = SurveyRoles.FAMILY;
        break;
      default:
        role = undefined;
        break;
    }
    return campaign.surveyTemplate ? (
      <Survey campaign={campaign} surveyTemplate={campaign.surveyTemplate} intervieweeRole={role} selfAssessment={selfAssessment} />
    ) : (
      <LoadSurvey campaign={campaign} intervieweeRole={role} selfAssessment={selfAssessment} />
    );
  }
  return <LegacyAssessmentForm campaign={campaign} intervieweeRole={intervieweeRole} selfAssessment={selfAssessment} />;
}

AssessmentForm.propTypes = {
  intervieweeRole: assessmentRolesType.isRequired,
  selfAssessment: PropTypes.bool,
  campaign: campaignType.isRequired,
};

AssessmentForm.defaultProps = {
  selfAssessment: false,
};

export default AssessmentForm;
