import { ApolloProvider } from '@apollo/client';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CampaignsAssessmentsRouter from './components/assessments/CampaignsAssessmentsRouter';
import InvitePeople from './components/InvitePeople';
import Loading from './components/Loading';
import Main from './components/Main';
import Onboarding from './components/onboarding/Onboarding';
import UserContext from './contexts/UserContext';
import ErrorBoundary from './ErrorBoundary';
import NoticeAgreement from './NoticeAgreement';
import { createCache, createClient } from './utils/apollo';
import './utils/i18n';

// ReactGA.initialize(process.env.GOOGLE_ANALYTICS_UA);
ReactGA.pageview(window.location.pathname + window.location.search);

axios.interceptors.request.use(
  // Do something before request is sent
  (config) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // eslint-disable-next-line no-param-reassign
    config.headers['X-CSRF-Token'] = csrfToken;
    return config;
  },
  // Do something with request error
  (error) => Promise.reject(error),
);

function App({ currentUser }) {
  return <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <UserContext.Provider value={JSON.parse(currentUser)}>
        <Router>
          <ApolloProvider client={createClient(createCache())}>
            <Routes>
              <Route path="/campaigns/:campaignId/assessments/*" element={<CampaignsAssessmentsRouter />} />
              <Route path="/onboarding/invitations" element={<InvitePeople />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/notice-de-consentement" element={<NoticeAgreement />} />
              <Route path="/*" element={<Main />} />
            </Routes>
          </ApolloProvider>
        </Router>
      </UserContext.Provider>
    </Suspense>
  </ErrorBoundary>
}

App.propTypes = {
  currentUser: PropTypes.string.isRequired,
};

export default App;
