import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function StepOtherOptionOnboarding() {
  const { t } = useTranslation();

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.other.title')}</h1>
        <p>{t('onboarding.other.description')}</p>
      </Col>
    </Row>
  );
}

export default StepOtherOptionOnboarding;
