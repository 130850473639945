import React from 'react';
import { Row, Col } from "react-bootstrap";
import { actionPlanType } from '../../types';
import ActionPlanGantt from './ActionPlanGantt';
import ActionsList from './ActionsList';

function ShowActionPlan({ actionPlan }) {
  return (
    <Row>
      <Col xs={12}>
        <ActionsList actionPlan={actionPlan} />
      </Col>
      <Col xs={12}>
        <ActionPlanGantt actionPlan={actionPlan} />
      </Col>
    </Row>
  );
}

ShowActionPlan.propTypes = {
  actionPlan: actionPlanType.isRequired,
};

export default ShowActionPlan;
