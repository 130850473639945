import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';

import CampaignWorkflowDataCollectionItem from './CampaignWorkflowDataCollectionItem';
import { campaignType, timelineStatusType } from '../../types';
import CampaignStatus from './CampaignStatus';
import getTimelineStatus from './getTimelineStatus';
import TimelineStatus from './TimelineStatus';
import { UpdateCampaignState } from '../../operations.graphql';

function TimelineItem({ status, step }) {
  const { t } = useTranslation();
  return (
    <div className={`item ${status}`}>
      <div className="status">
        <h6>{t(`campaigns.active.${step}.title`)}</h6>
      </div>
    </div>
  );
}

TimelineItem.propTypes = {
  step: PropTypes.string.isRequired,
  status: timelineStatusType.isRequired,
};

function Timeline({ campaign }) {
  return (
    <div className="timeline mt-4">
      <TimelineItem step={CampaignStatus.STARTED} status={getTimelineStatus(CampaignStatus.STARTED, campaign.campaignState)} />
      <TimelineItem step={CampaignStatus.DATA_COLLECTION} status={getTimelineStatus(CampaignStatus.DATA_COLLECTION, campaign.campaignState)} />
      <TimelineItem
        step={CampaignStatus.WAITING_FOR_DATA_ANALYSIS}
        status={getTimelineStatus(CampaignStatus.WAITING_FOR_DATA_ANALYSIS, campaign.campaignState)}
      />
      <TimelineItem step={CampaignStatus.REPORT_PUBLISHED} status={getTimelineStatus(CampaignStatus.REPORT_PUBLISHED, campaign.campaignState)} />
    </div>
  );
}

Timeline.propTypes = {
  campaign: campaignType.isRequired,
};

function CampaignActiveBody({ campaign }) {
  const { t } = useTranslation();
  const { campaignState } = campaign;
  const [updateCampaignState, { loading }] = useMutation(UpdateCampaignState);

  switch (campaignState) {
    case CampaignStatus.DATA_COLLECTION: {
      return <CampaignWorkflowDataCollectionItem campaign={campaign} status={getTimelineStatus(CampaignStatus.DATA_COLLECTION, campaign.campaignState)} />;
    }
    case CampaignStatus.STARTED: {
      return (
        <Trans i18nKey="multiline">
          <p className="item-body text-center p-3">
            {t(`campaigns.active.${campaign.campaignState}.${TimelineStatus.ACTIVE}`)}
            <Button
              className="mt-3"
              disabled={loading}
              onClick={() =>
                updateCampaignState({
                  variables: {
                    campaignId: campaign.id,
                    event: 'schedule_data_collection',
                  },
                })
              }
            >
              {t(`campaigns.active.${campaign.campaignState}.startDataCollection`)}
            </Button>
          </p>
        </Trans>
      );
    }
    default: {
      return (
        <Trans i18nKey="multiline">
          <p className="item-body text-center p-3">{t(`campaigns.active.${campaign.campaignState}.${TimelineStatus.ACTIVE}`)}</p>
        </Trans>
      );
    }
  }
}

CampaignActiveBody.propTypes = {
  campaign: campaignType.isRequired,
};

function CampaignActive({ campaign, title }) {
  return (
    <Card className={campaign.campaignState}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Row className="progress-steps">
          <Col xs={12}>
            <Timeline campaign={campaign} />
            <CampaignActiveBody campaign={campaign} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

CampaignActive.propTypes = {
  campaign: campaignType.isRequired,
  title: PropTypes.string.isRequired,
};

export default CampaignActive;
