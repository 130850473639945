import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function CategoryRoleAnswer({ categoryRole, answers, hasReachedThreshold }) {
  const { t } = useTranslation();

  const color = hasReachedThreshold ? '#20E647' : '#F44336';
  const options = {
    chart: {
      type: 'radialBar',
    },

    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: '70%',
        },

        dataLabels: {
          showOn: 'always',
          name: {
            show: false,
          },
          value: {
            offsetY: 10,
            color: '#111',
            fontSize: '30px',
            show: true,
          },
        },
      },
    },

    fill: {
      colors: [color],
    },

    stroke: {
      lineCap: 'round',
    },
  };

  return (
    <div className="text-center">
      <div id="chart" style={{ padding: 0 }}>
        <ReactApexChart options={options} series={[answers]} type="radialBar" height="180px" />
        <span className="mr-1">{t(`campaigns.active.data_collection.${categoryRole}Answered.description`)}</span>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${categoryRole}`}>{t(`campaigns.active.data_collection.${categoryRole}Answered.tooltip`)}</Tooltip>}
        >
          <FaInfoCircle className="mx-1" />
        </OverlayTrigger>
      </div>
    </div>
  );
}

const FaInfoCircle = React.forwardRef((props, ref) => <FontAwesomeIcon ref={ref} icon={faInfoCircle} {...props} />);
FaInfoCircle.displayName = 'FaInfoCircle';

CategoryRoleAnswer.propTypes = {
  categoryRole: PropTypes.string.isRequired,
  answers: PropTypes.number.isRequired,
  hasReachedThreshold: PropTypes.bool.isRequired,
};

export default CategoryRoleAnswer;
