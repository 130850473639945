import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSpeechSynthesis } from 'react-speech-kit';
import AssessmentQuestionInput from './AssessmentQuestionInput';

function AssessmentQuestion({
  type,
  value,
  name,
  options,
  spokenOptions,
  interviewee,
  handleCheckboxSelection,
  handleRadioSelection,
  onTextInputSubmitted,
  gender,
}) {
  const { t } = useTranslation();
  const { speak, voices, speaking } = useSpeechSynthesis();
  const langVoices = voices.filter((voice) => voice.lang === 'fr-FR');

  return (
    <Row className="pt-5">
      <Col>
        <p className="display-4">
          {t(`campaigns.assessments.${interviewee}.${name}`, { context: gender })}
          <Button
            className="ml-2 text-dark"
            variant="light"
            onClick={() =>
              speak({
                text: t(`campaigns.assessments.${interviewee}.${name}`, { context: gender }) + spokenOptions,
                voice: langVoices[langVoices.length - 1],
              })
            }
            disabled={speaking}
          >
            <FontAwesomeIcon icon={faVolumeUp} />
          </Button>
        </p>
      </Col>
      <Col xs={12} className="pt-5 justify-content-center">
        <Row>
          <div className="d-grid gap-1">
            <AssessmentQuestionInput
              type={type}
              value={value}
              name={name}
              options={options}
              interviewee={interviewee}
              handleCheckboxSelection={handleCheckboxSelection}
              handleRadioSelection={handleRadioSelection}
              onTextInputSubmitted={onTextInputSubmitted}
              gender={gender}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
}

AssessmentQuestion.propTypes = {
  spokenOptions: PropTypes.string,
  ...AssessmentQuestionInput.PropTypes,
};

AssessmentQuestion.defaultProps = {
  spokenOptions: '',
};

export default AssessmentQuestion;
