import React from 'react';
import { Card } from 'react-bootstrap';

function NoticeAgreement() {
  return <Card.Text className="px-5 pb-5 lead">
    <h1 className="text-center">
      Notice d&apos;information sur le traitement de vos données personnelles par ARBITRYUM et formulaire de recueil de votre consentement
    </h1>
    <h4>Objet</h4>
    <p>
      Mesure du respect des libertés des résidents en établissement et des écarts de perception avec les proches et professionnels via l&apos;outil numérique «
      Libre jusqu&apos;au bout de sa vie »
    </p>
    <h4>Responsable du traitement</h4>
    <p>
      ARBITRYUM, entreprise sociale reconnue de l&apos;Économie Sociale et Solidaire immatriculée au Répertoire national des entreprises et des établissements
      sous le n° 841 086 887 R.C.S Créteil et dont le siège social est situé 52 rue de Vincennes 94700 Maisons-Alfort, représentée par sa co-fondatrice, la
      Docteure Sabrina Albayrak et par délégation, sa co-fondatrice Madame Sylvana Discour.
    </p>
    <h4>Établissement/structure</h4>
    <p>ARBITRYUM, 52 rue de Vincennes 94700, Maisons-Alfort</p>
    <br />
    <p>
      Madame, Monsieur,
      <br />
      Cette notice d&apos;information vous concerne. Il vous est proposé de participer à une enquête sur le respect des libertés en EHPAD ou en
      résidence-autonomie via une plateforme numérique nommée « Libre jusqu&apos;au bout de sa vie ».
      <p>
        La présente notice a pour but de vous informer des modalités de mise en œuvre du traitement de vos données personnelles ainsi que vos droits, notamment
        le droit de retirer votre consentement. Nous vous demandons de la lire attentivement.
      </p>
    </p>
    <h2>1. Réglementation applicable à vos données personnelles</h2>
    <p>
      Vos données personnelles sont traitées par ARBITRYUM ci-après désignées «ARBITRYUM ou « nous »), qui agit en qualité de responsable de traitement. Vos
      données personnelles nous sont confiées dans le seul but d&apos;analyser les effets du respect des libertés sur la qualité de vie, l&apos;ensemble de ces
      données sont anonymisées et ne feront nullement l&apos;objet de statistiques individuelles.
      <p>
        Le traitement de vos données personnelles est réalisé dans le respect des dispositions suivantes :
        <ul>
          <li>le Règlement européen n° 2016/679 dit « Règlement général sur la protection des données » (ou « RGDP ») ;</li>
          <li>la Loi n°78-17 du 6 janvier 1978 relative à l&apos;informatique, aux fichiers et aux libertés modifiée.</li>
        </ul>
      </p>
    </p>
    <h2>2. Nature des données personnelles collectées vous concernant</h2>
    <p>
      Nous collectons et traitons uniquement les données à caractère personnel suivantes :
      <ul>
        <li>données socio-démographiques</li>
        <li>perception de la qualité de vie</li>
        <li>perception du respect des libertés</li>
      </ul>
    </p>
    <h2>3. Finalités du traitement de vos données personnelles</h2>
    <p>
      ARBITRYUM traite vos données personnelles aux fins suivantes :
      <ul>
        <li>Obtenir un diagnostic général sur la tendance en matière de respect des libertés par établissement</li>
        <li>Obtenir un diagnostic général sur la tendance en matière de respect des libertés par groupe d&apos;établissements</li>
      </ul>
      <p>Les données collectées dans ce cadre ne sont utilisées dans aucun autre but.</p>
      <p>Ces traitements sont fondés sur votre consentement.</p>
    </p>
    <h2>4. Destinataire de vos données personnelles</h2>
    <p>
      Vos données personnelles collectées dans le cadre de « Libre jusqu&apos;au bout de sa vie » sont strictement confidentielles et destinées exclusivement
      aux personnels habilités d&apos;ARBITRYUM.
      <p>
        Toutefois, pour les besoins de la réalisation des finalités visées ci-avant, vos données personnelles sont transmises et traitées par notre
        sous-traitant SCALINGO.
      </p>
      <p>
        La société SCALINGO met en œuvre des mesures de sécurité appropriées afin de garantir la confidentialité et l&apos;intégrité de vos données
        personnelles. Elle ne dispose d&apos;aucun droit d&apos;utilisation sur vos données personnelles excepté à des fins d&apos;exécution des prestations qui
        lui sont confiées par ARBITRYUM et sous leur contrôle.
      </p>
    </p>

    <h2>5. Durée de conservation de vos données personnelles</h2>
    <p>
      Vos données personnelles sont conservées en base active pendant 5 ans puis supprimées, sous réserve de l&apos;application de dispositions législatives ou
      réglementaires en vigueur.
    </p>

    <h2>6. Sécurité de vos données personnelles</h2>
    <p>
      ARBITRYUM met en œuvre toutes les mesures techniques et organisationnelles appropriées au regard de la nature de vos données personnelles et des risques
      présentés par le traitement dont elles font l&apos;objet, afin de préserver leur sécurité et leur confidentialité et, notamment, empêcher que celles-ci ne
      soient déformées, endommagées, ou que des tiers non autorisés y aient accès.
    </p>
    <h2>7. Vos droits sur vos données personnelles</h2>
    <p>
      Vous pouvez accéder aux données vous concernant, les rectifier ou les faire effacer. Vous disposez également d&apos;un droit à la limitation du traitement
      de vos données. Vous pouvez retirer à tout moment votre consentement au traitement de vos données. Pour exercer ces droits ou pour toute question sur le
      traitement de vos données, vous pouvez contacter notre Délégué à la protection des données (ci-après désigné « DPO ») :
      <ul>
        <li>Contacter notre direction technique contact@arbitryum.fr</li>
        <li>Contacter notre DPO par courrier postal : ARBITRYUM 52, rue de Vincennes 94700, Maisons-Alfort</li>
      </ul>
      <p>
        Si vous estimez, après avoir nous contacté, que vos droits ne sont pas respectés, vous pouvez adresser une réclamation à l&apos;Autorité française de
        protection des données :
      </p>
      <p>CNIL 3 Place de Fontenoy - TSA 80715 75334 PARIS CEDEX 07</p>
      <p>Tél. : +33 (1) 53 73 22 22</p>
      <p>Site internet: https://www.cnil.fr/fr/plaintes</p>
    </p>

    <h2>8. Consentement au traitement de vos données personnelles</h2>
    <p>
      La collecte et le traitement de vos données personnelles à des fins de Mesure du respect des libertés des résidents en établissement et des écarts de
      perception avec les proches et professionnels via l&apos;outil numérique « Libre jusqu&apos;au bout de sa vie » est fondé sur votre consentement.
    </p>
    <br />
  </Card.Text>
}

export default NoticeAgreement;
