import React from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Collapse } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faUser, faClock } from '@fortawesome/free-solid-svg-icons';
import { goodPracticeType } from '../../types';

function ActionDetails({ action, expandedActionInfo, selectedAction, selectAction, deselectAction, setExpandedActionInfo }) {
  const { t } = useTranslation();

  return (
    <Card className="mb-3" key={action.id}>
      <Card.Body>
        <Card.Title>{action.title}</Card.Title>
        <div className="d-flex">
          <ul className="list-inline">
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faCoins} className="mr-1" />
              {`${action.standardBudget} €`}
            </li>
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faUser} className="mr-1" />
              {t('campaigns.actionPlan.new.fullTimeEquivalent', { fullTimeEquivalent: action.fullTimeEquivalent })}
            </li>
            <li className="list-inline-item">
              <FontAwesomeIcon icon={faClock} className="mr-1" />
              {moment.duration(action.durationMonths, 'months').humanize()}
            </li>
          </ul>
        </div>
        <Collapse in={expandedActionInfo}>
          {/* eslint-disable-next-line react/no-danger */}
          <p id="example-collapse-text" dangerouslySetInnerHTML={{ __html: action.description }} />
        </Collapse>
        <div className="d-flex justify-content-between">
          <Button
            variant="light"
            onClick={() => expandedActionInfo === action.id ? setExpandedActionInfo(null) : setExpandedActionInfo(action.id)}
            aria-controls="example-collapse-text"
            aria-expanded={expandedActionInfo}
          >
            {t('campaigns.actionPlan.new.moreInfo')}
          </Button>
          {selectedAction
            && <Button variant="danger" onClick={() => deselectAction(action.id)}>{t('campaigns.actionPlan.new.removeFromPlan')}</Button>
            || <Button onClick={() => selectAction(action.id)}>{t('campaigns.actionPlan.new.addToPlan')}</Button>}
        </div>
      </Card.Body>
    </Card>
  );
}


ActionDetails.propTypes = {
  action: goodPracticeType.isRequired,
  expandedActionInfo: PropTypes.bool,
  selectedAction: PropTypes.bool,
  selectAction: PropTypes.func.isRequired,
  deselectAction: PropTypes.func.isRequired,
  setExpandedActionInfo: PropTypes.func.isRequired,
}

ActionDetails.defaultProps = {
  expandedActionInfo: false,
  selectedAction: false,
}

export default ActionDetails;
