import React from 'react';
import { useParams, Routes, Route } from 'react-router-dom';
import { Code } from 'react-content-loader';
import { useQuery } from '@apollo/client';
import { Card } from 'react-bootstrap';

import { GetCampaign } from '../../operations.graphql';
import CampaignCard from './CampaignCard';
import NewActionPlan from '../action_plans/NewActionPlan';
import CampaignInvitationsRouter from '../invitations/CampaignInvitationsRouter';

function CampaignRouter() {
  const { campaignId } = useParams();
  const { loading, error, data } = useQuery(GetCampaign, {
    variables: { campaignId },
  });

  if (loading) {
    return (
      <Card>
        <Card.Body>
          <Code />
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return <div>{`${error}`}</div>;
  }

  const { campaign } = data;

  return (
    <Routes>
      <Route index element={<CampaignCard campaign={campaign} />} />
      <Route path="action_plan/new" element={<NewActionPlan campaign={campaign} />} />
      <Route path="invitations/*" element={<CampaignInvitationsRouter />} />
    </Routes>
  );
}

export default CampaignRouter;
