import { string } from 'prop-types';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ClipboardCopy({ copyText }) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text);
    }

    return document.execCommand('copy', true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Form.Control type="text" value={copyText} readOnly />
      {/* Bind our handler function to the onClick button property */}
      <Button variant="primary" onClick={handleCopyClick}>
        <span>{isCopied ? t('clipboardCopy.copied') : t('clipboardCopy.copy')}</span>
      </Button>
    </>
  );
}

ClipboardCopy.propTypes = {
  copyText: string.isRequired,
};

export default ClipboardCopy;
