const REPORT_CATEGORIES = {
  freedomAndSecurityTheme: [
    'respectForLifestyle',
    'connectionWithTheOutside',
    'personalizedSupport',
    'accessibilityOfCollectivePlaces',
    'contention',
    'mobilityAid',
  ],
  chooseAndOwnYourPlaceTheme: [
    'fosterResidentsRelationshipsWithFamilies',
    'mixedPopulations',
    'fosterResidentsRelationshipsWithLovedOnes',
    'residentsWelcome',
    'consent',
    'consumptionOfEverydayGoods',
  ],
  chooseHowToBeHelpedTheme: [
    'respectForTheRhythmOfLife',
    'respectForAutonomy',
    'choiceOfAccompaniment',
    'adaptationOfTheLivingSpace',
    'welcomingFamilies'
  ],
  haveControlYourOwnBodyTheme: [
    'respectForWishes',
    'adaptationToNeeds',
    'respectForDeath',
    'respectForPrivacy',
    'adaptationToVulnerabilities'
  ],
  getInformedToStayUsefulTheme: [
    'inclusionInDecisions',
    'informationOnTheLifeOfTheEstablishment'
  ],
  accessLeisureAndCultureTheme: [
    'openingOfTheStructureToTheOutside',
    'openingOfTheStructure',
    'informationOnActivities',
    'adaptationOfActivities',
    'residentConsultation',
  ],
  facilityRelationshipsTheme: [
    'feelingOfRespectForFreedoms',
    'familyInvolvement',
    'establishLastingRelationshipsAndTrust',
    'participation'
  ],
};

export default REPORT_CATEGORIES;
