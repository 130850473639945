import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { SendInvitations } from '../operations.graphql';
import EmailsInput from './inputs/EmailsInput';

function InvitePeople() {
  const { t } = useTranslation();
  const [emailList, setEmailList] = useState([]);
  const [error, setError] = useState(null);
  const { register, handleSubmit } = useForm();
  const [sendInvitations, { loading, data }] = useMutation(SendInvitations);
  const { ref } = register('EmailsInput');

  if (data) {
    const {
      sendInvitations: { users, errors },
    } = data;

    if (errors && errors.length > 0) {
      console.error(errors);
    } else if (users) {
      return <Navigate to="/" />;
    }
  }

  const onSubmit = () => {
    if (emailList.length === 0) {
      setError('Veuillez entrer au moins une adresse');
      return;
    }
    sendInvitations({
      variables: {
        emailList,
      },
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <h1>{t('onboarding.invitations.title')}</h1>
      </Col>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <p>{t('onboarding.invitations.description')}</p>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="d-grid gap-1">
                <EmailsInput inputRef={ref} emailList={emailList} setEmailList={setEmailList} />
                <Button type="submit" disabled={loading}>
                  {t('onboarding.invitations.submit')}
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default InvitePeople;
