const QuestionTypes = {
  RADIO: 1,
  SCALE: 2,
  CHECKBOX: 3,
  TEXT: 4,
  TEXTAREA: 5,
  NUMBER: 6,
  options: {
    1: [true, false],
    2: Array.from(Array(11).keys()),
  }
};

export default QuestionTypes;
