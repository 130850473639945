import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { actionPlanType, campaignType } from '../../types';
import ShowActionPlan from '../action_plans/ShowActionPlan';
import CampaignsList from '../campaigns/CampaignsList';

function FacilitySummary({ facility }) {
  const { t } = useTranslation();
  const { actionPlan } = facility;

  return (
    <Row className="mb-3">
      <Col xs={12} className="pb-3">
        <div className="dashboard-title">
          <h3>{facility.name}</h3>
        </div>
      </Col>
      <Col xs={12} className="pb-3">
        {(actionPlan && (
          <Card>
            <Card.Body>
              <ShowActionPlan actionPlan={actionPlan} />
            </Card.Body>
            <Card.Footer className="text-end">
              <Button href={`/action_plans/${actionPlan.id}`}>{t('facilities.summary.manageActionPlan')}</Button>
            </Card.Footer>
          </Card>
        )) || <CampaignsList key={facility.id} facility={facility} />}
      </Col>
    </Row>
  );
}

FacilitySummary.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    campaigns: PropTypes.arrayOf(campaignType).isRequired,
    actionPlan: actionPlanType,
  }).isRequired,
};

export default FacilitySummary;
