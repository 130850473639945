import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { timelineStatusType } from '../../types';

function CampaignWorkflowItem({ step, status }) {
  const { t } = useTranslation();
  return (
    <div className={`item ${status}`}>
      <div className="status">
        <h6>{t(`campaigns.active.${step}.title`)}</h6>
        <Trans i18nKey="multiline">
          <p className="item-body text-justify">{t(`campaigns.active.${step}.${status}`)}</p>
        </Trans>
      </div>
    </div>
  );
}

CampaignWorkflowItem.propTypes = {
  step: PropTypes.string.isRequired,
  status: timelineStatusType.isRequired,
};

export default CampaignWorkflowItem;
