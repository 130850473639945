import React from 'react';
import { CSSTransition } from 'react-transition-group';

function SlideTransition(props) {
  return <CSSTransition
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    classNames="slide"
    addEndListener={(node, done) => {
      node.addEventListener('transitionend', done, false);
    }}
  />
}

export default SlideTransition;
