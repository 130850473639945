import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Badge, Form,
} from 'react-bootstrap';

function EmailsInput({ inputRef, emailList, setEmailList }) {
  const { t } = useTranslation();
  const [ value, setValue ] = useState('');
  const [ error, setError ] = useState(null);

  const handleChange = (evt) => {
    setValue(evt.target.value);
  };

  const isEmail = (email) => 
    // eslint-disable-next-line no-useless-escape
     /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
  

  const isInList = (email) => emailList.includes(email)

  const isValid = (email) => {
    if (!isEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false
    }

    if (isInList(email)) {
      setError(`${email} has already been added.`);
      return false
    }

    return true;
  }

  const handleKeyDown = (evt) => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault();

      const email = value.trim();

      if (email && isValid(email)) {
        setEmailList([...emailList, email]);
        setValue('');
        setError(null);
      }
    }
  };

  const handleDelete = (toBeRemoved) => {
    setEmailList(emailList.filter(email => email !== toBeRemoved));
  };

  return (
    <Form.Group controlId="groupName">
      <div>
        {emailList.map(email => (
          <Badge
            key={email}
            variant="primary"
            style={{fontSize: '100%', lineHeight: 1.5}}
            className="mb-3 mr-3"
            pill
          >
            {email}

            <button
              type="button"
              className="close ml-1"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => handleDelete(email)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Badge>
        ))}
      </div>
      <Form.Control
        name="group[name]"
        type="text"
        ref={inputRef}
        placeholder={t('onboarding.invitations.placeholder')}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        isInvalid={!!error}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    </Form.Group>
  )
}

EmailsInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  emailList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  setEmailList: PropTypes.func.isRequired,
}

export default EmailsInput;
