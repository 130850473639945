import PropTypes from 'prop-types';
import React from 'react';
import { Accordion, Button, Card, Col, ListGroup, Nav, ProgressBar, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { campaignType } from '../../types';
import REPORT_CATEGORIES from '../../utils/ReportCategories';

function CampaignReportResult({ category, result, as, textStyles }) {
  const TextComponent = as;
  return (
    <Row>
      <Col xs={8}>
        <TextComponent className={textStyles}>{category}</TextComponent>
      </Col>
      <Col xs={4} className="align-self-center">
        {result && <ProgressBar now={result} />}
      </Col>
    </Row>
  );
}

CampaignReportResult.propTypes = {
  category: PropTypes.string.isRequired,
  result: PropTypes.number,
  as: PropTypes.elementType,
  textStyles: PropTypes.string,
};

CampaignReportResult.defaultProps = {
  result: undefined,
  as: 'div',
  textStyles: '',
};

function CampaignReportSummary({ campaign, title }) {
  const { t } = useTranslation();
  const {
    report: { results, standardDeviations },
  } = campaign;

  const sortedResultsKeys = Object.keys(results)
    .filter((value) =>
      Object.entries(REPORT_CATEGORIES)
        .flatMap((entry) => entry[1])
        .includes(value),
    )
    .sort((a, b) => results[b] - results[a]);

  const sortedDeviationsKeys = Object.keys(standardDeviations)
    .filter((value) =>
      Object.entries(REPORT_CATEGORIES)
        .flatMap((entry) => entry[1])
        .includes(value),
    )
    .sort((a, b) => standardDeviations[b] - standardDeviations[a]);

  return (
    <Card className={campaign.campaignState}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Row>
          <Col xs={12}>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="top">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="top">Les points forts</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="bottom">Les points de vigilance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="quickWins">Les écarts de perception</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="all">Synthèse</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="top">
                      {sortedResultsKeys.slice(0, 3).map((category) => (
                        <ListGroup.Item item={category} key={category}>
                          <CampaignReportResult category={t(`campaigns.report.categories.${category}`)} result={results[category]} textStyles="lead" />
                        </ListGroup.Item>
                      ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="bottom">
                      {sortedResultsKeys.slice(Math.max(sortedResultsKeys.length - 3, 1)).map((category) => (
                        <ListGroup.Item item={category} key={category}>
                          <CampaignReportResult category={t(`campaigns.report.categories.${category}`)} result={results[category]} textStyles="lead" />
                        </ListGroup.Item>
                      ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="quickWins">
                      {sortedDeviationsKeys.slice(0, 3).map((category) => (
                        <ListGroup.Item item={category} key={category}>
                          <CampaignReportResult category={t(`campaigns.report.categories.${category}`)} textStyles="lead" />
                        </ListGroup.Item>
                      ))}
                    </Tab.Pane>
                    <Tab.Pane eventKey="all">
                      <Accordion>
                        {Object.entries(REPORT_CATEGORIES).map(([theme, categories]) => (
                          <Card key={theme}>
                            <Accordion.Toggle as={Card.Header} eventKey={theme}>
                              <CampaignReportResult category={t(`campaigns.report.themes.${theme}`)} result={results[theme]} textStyles="lead" />
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={theme}>
                              <Card.Body>
                                {categories.map((category) => (
                                  <CampaignReportResult key={category} category={t(`campaigns.report.categories.${category}`)} result={results[category]} />
                                ))}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))}
                      </Accordion>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="text-muted">
        <LinkContainer to={`/campaigns/${campaign.id}/action_plan/new`}>
          <div className="d-grid gap-1">
            <Button variant="primary">{t('campaigns.report.newActionPlan')}</Button>
          </div>
        </LinkContainer>
      </Card.Footer>
    </Card>
  );
}

CampaignReportSummary.propTypes = {
  campaign: campaignType.isRequired,
  title: PropTypes.string.isRequired,
};

export default CampaignReportSummary;
