import React, { useState } from 'react';
import { Alert, Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { campaignType } from '../../types';

function NewCampaignAssessment({ campaign }) {
  const { t } = useTranslation();
  const { campaignId } = useParams();
  const [showAlert, setShowAlert] = useState(true);
  const [agree, setAgree] = useState(false);
  return (
    <Container className="d-flex flex-column justify-content-between p-3 min-vh-100">
      <Row>
        <Col xs={12}>
          <h1>{t('campaigns.assessments.new.title')}</h1>
        </Col>
        <Col xs={12}>
          {!campaign.currentUserAnswered && showAlert && (
            <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>
              {t('campaigns.assessments.new.currentUserDidNotAnswer')}
              <LinkContainer to={`/campaigns/${campaignId || campaign.id}/assessments/current_user`}>
                <Alert.Link>{t('campaigns.assessments.new.answerNow')}</Alert.Link>
              </LinkContainer>
            </Alert>
          )}
        </Col>
      </Row>
      <Row className="m-5 justify-content-md-center">
        {!agree ? (
          <Col className="d-grid gap-1">
            <Card.Text className="text-center mb-5 lead">
              <Trans i18nKey="survey.agreeToTerms" t={t} components={[<Link to="/notice-de-consentement" target="_blank" />]} />
            </Card.Text>
            <Button className="mb-3" variant="outline-primary" size="lg" onClick={() => setAgree(!agree)}>
              Oui
            </Button>
            <LinkContainer to="/">
              <Button className="mb-3" variant="outline-primary" size="lg">
                Non
              </Button>
            </LinkContainer>
          </Col>
        ) : (
          <Col>
            <Row>
              <Col>
                <Card.Text className="text-center mb-5 lead">{t('campaigns.assessments.new.selectRole')}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 4, offset: 4 }}>
                <div className="d-grid gap-1">
                  <LinkContainer to={`/campaigns/${campaignId || campaign.id}/assessments/employee`}>
                    <Button className="mb-3" variant="outline-primary" size="lg">
                      {t('campaigns.assessments.new.professional')}
                    </Button>
                  </LinkContainer>
                  <LinkContainer to={`/campaigns/${campaignId || campaign.id}/assessments/customer`}>
                    <Button className="mb-3" variant="outline-primary" size="lg">
                      {t('campaigns.assessments.new.customer')}
                    </Button>
                  </LinkContainer>
                  <LinkContainer to={`/campaigns/${campaignId || campaign.id}/assessments/caregiver`}>
                    <Button className="mb-3" variant="outline-primary" size="lg">
                      {t('campaigns.assessments.new.caregiver')}
                    </Button>
                  </LinkContainer>
                </div>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12}>
          <LinkContainer to="/">
            <Button variant="link">{t('campaigns.assessments.new.back')}</Button>
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  );
}

NewCampaignAssessment.propTypes = {
  campaign: campaignType.isRequired,
};

export default NewCampaignAssessment;
