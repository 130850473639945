import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import ActionPlanSummary from './ActionPlanSummary';
import { goodPracticeType } from '../../types';

function ActionListLayout({ children, selectedActions, facility, updateActionStartDate, updateActionEndDate }) {
  return (
    <Row>
      <Col xs={3}>
        <ActionPlanSummary
          selectedActions={selectedActions}
          facility={facility}
          updateActionStartDate={updateActionStartDate}
          updateActionEndDate={updateActionEndDate}
        />
      </Col>
      <Col xs={9} className="mt-3">
        {children}
      </Col>
    </Row>
  );
}

ActionListLayout.propTypes = {
  children: PropTypes.node.isRequired,
  selectedActions: PropTypes.arrayOf(goodPracticeType),
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  updateActionStartDate: PropTypes.func.isRequired,
  updateActionEndDate: PropTypes.func.isRequired,
}

ActionListLayout.defaultProps = {
  selectedActions: [],
}

export default ActionListLayout;
