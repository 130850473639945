import { useMutation } from '@apollo/client';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { UpdateCampaignState } from '../../operations.graphql';
import { campaignType } from '../../types';
import CampaignStatus from './CampaignStatus';
import CampaignWorkflowItem from './CampaignWorkflowItem';
import CategoryRoleAnswer from './CategoryRoleAnswer';

function CampaignWorkflowDataCollectionItem({ campaign }) {
  const { t } = useTranslation();
  const [updateCampaignState, { loading }] = useMutation(UpdateCampaignState);
  const [showAlert, setShowAlert] = useState(true);

  if (campaign.campaignState !== CampaignStatus.STARTED && campaign.campaignState !== CampaignStatus.DATA_COLLECTION) {
    return <CampaignWorkflowItem step="data_collection" status="complete" />;
  }

  return (
    <Row className="m-3">
      {campaign.currentUserAnswered ||
        (showAlert && (
          <Col sm={12}>
            <Alert variant="info" onClose={() => setShowAlert(false)} dismissible>
              {t('campaigns.assessments.new.currentUserDidNotAnswer')}
              <LinkContainer to={`/campaigns/${campaign.id}/assessments/current_user`}>
                <Alert.Link>{t('campaigns.assessments.new.answerNow')}</Alert.Link>
              </LinkContainer>
            </Alert>
          </Col>
        ))}
      <Col sm={3}>
        <CategoryRoleAnswer answers={campaign.professionalsAnswerRate} hasReachedThreshold={campaign.professionalsAnswered} categoryRole="professionals" />
      </Col>
      <Col sm={3}>
        <CategoryRoleAnswer answers={campaign.customersAnswerRate} hasReachedThreshold={campaign.customersAnswered} categoryRole="customers" />
      </Col>
      <Col sm={3}>
        <CategoryRoleAnswer answers={campaign.caregiversAnswerRate} hasReachedThreshold={campaign.caregiversAnswered} categoryRole="caregivers" />
      </Col>
      <Col sm={3}>
        <div className="d-grid gap-2">
          <LinkContainer to={`/campaigns/${campaign.id}/assessments/new`}>
            <Button variant="primary">
              {t('campaigns.active.newAssessment')}
              <FontAwesomeIcon className="ms-1" icon={faCaretRight} />
            </Button>
          </LinkContainer>
          <LinkContainer to={`/campaigns/${campaign.id}/invitations/new`}>
            <Button variant="primary">
              {t('campaigns.active.invite')}
              <FontAwesomeIcon className="ms-1" icon={faCaretRight} />
            </Button>
          </LinkContainer>
          <Button
            variant="outline-primary"
            disabled={!(campaign.professionalsAnswered && campaign.customersAnswered && campaign.caregiversAnswered && campaign.currentUserAnswered) || loading}
            onClick={() =>
              updateCampaignState({
                variables: {
                  campaignId: campaign.id,
                  event: 'start_analysis',
                },
              })
            }
          >
            {t('campaigns.active.validate')}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

CampaignWorkflowDataCollectionItem.propTypes = {
  campaign: campaignType.isRequired,
};

export default CampaignWorkflowDataCollectionItem;
