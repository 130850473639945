import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { campaignType } from '../../types';
import CampaignCard from './CampaignCard';
import CampaignStatus from './CampaignStatus';
import EmptyCampaigns from './EmptyCampaigns';
import NoActiveCampaignCard from './NoActiveCampaignCard';

function CampaignsList({ facility }) {
  const { campaigns } = facility;

  if (campaigns.length === 0) {
    return <EmptyCampaigns facilityId={facility.id} />;
  }

  const activeCampaigns = campaigns.filter(({ campaignState }) => campaignState !== CampaignStatus.FINISHED);
  if (activeCampaigns.length === 0) {
    return <NoActiveCampaignCard facilityId={facility.id} />;
  }

  return campaigns.map((campaign, index) => (
    <Row key={campaign.id}>
      <Col className="pb-3">
        <CampaignCard
          campaign={campaign}
          index={campaigns.length - index}
        />
      </Col>
    </Row>
  ));
}

CampaignsList.propTypes = {
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    campaigns: PropTypes.arrayOf(campaignType).isRequired,
  }).isRequired,
};

export default CampaignsList;
