import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CreateActionPlan } from '../../operations.graphql';
import { goodPracticeType } from '../../types';

const DateInput = React.forwardRef(({ value, onClick, label }, ref) => (
  <Form.Group>
    <Form.Label>{label}</Form.Label>
    <Form.Control value={value} ref={ref} onClick={onClick} onChange={onClick} />
  </Form.Group>
));

DateInput.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

function Content({ selectedActions, facility, updateActionStartDate, updateActionEndDate }) {
  const { t } = useTranslation();
  const [createActionPlan, { data, errors, loading }] = useMutation(CreateActionPlan);

  if (errors) {
    return <p className="text-center text-danger">{errors}</p>;
  }

  if (data) {
    return <Navigate to={`/action_plans/${data.createActionPlan.id}`} />;
  }

  return (
    <Form>
      <div className="d-grid gap-1">
        <ul>
          {selectedActions.map((action) => (
            <li key={action.id}>
              {action.title}
              <DatePicker
                selected={action.startDate}
                onChange={(date) => updateActionStartDate(action, date)}
                customInput={<DateInput label={t(`campaigns.actionPlan.new.actions.startDate`)} />}
              />
              <DatePicker
                selected={action.endDate}
                onChange={(date) => updateActionEndDate(action, date)}
                customInput={<DateInput label={t(`campaigns.actionPlan.new.actions.endDate`)} />}
              />
            </li>
          ))}
        </ul>
        <Button
          disabled={loading}
          onClick={() =>
            createActionPlan({
              variables: {
                facilityId: facility.id,
                actionPlanAttributes: {
                  actionsAttributes: selectedActions.map((action) => ({
                    startDate: action.startDate,
                    endDate: action.endDate,
                    goodPracticeId: action.id,
                  })),
                },
              },
            })
          }
        >
          {t('campaigns.actionPlan.new.createPlan')}
        </Button>
      </div>
    </Form>
  );
}

Content.propTypes = {
  selectedActions: PropTypes.arrayOf(goodPracticeType).isRequired,
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  updateActionStartDate: PropTypes.func.isRequired,
  updateActionEndDate: PropTypes.func.isRequired,
};

function ActionPlanSummary({ selectedActions, facility, updateActionStartDate, updateActionEndDate }) {
  const { t } = useTranslation();

  return (
    <div className="sticky-top">
      <div className="pt-3">
        <Card>
          <Card.Body>
            <Card.Title>{t('campaigns.actionPlan.new.myPlan')}</Card.Title>
            {(Array.isArray(selectedActions) && selectedActions.length && (
              <Content
                selectedActions={selectedActions}
                facility={facility}
                updateActionStartDate={updateActionStartDate}
                updateActionEndDate={updateActionEndDate}
              />
            )) || <p>{t('campaigns.actionPlan.new.selectActions')}</p>}
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

ActionPlanSummary.propTypes = {
  selectedActions: PropTypes.arrayOf(goodPracticeType),
  facility: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  updateActionStartDate: PropTypes.func.isRequired,
  updateActionEndDate: PropTypes.func.isRequired,
};

ActionPlanSummary.defaultProps = {
  selectedActions: [],
  facility: null,
};

export default ActionPlanSummary;
